import { SWITCH_EDIT_MODE } from './actionTypes'

export function switchEditMode(isActive) {

    localStorage.setItem('isEditModeActive', isActive)

    return {
        type: SWITCH_EDIT_MODE,
        payload: isActive
    }
}