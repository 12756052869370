import React from 'react'
import { REDIRECT, RESET_REDIRECT } from './actionTypes'
import { createBrowserHistory } from 'history'
import { getFirebase } from '../../data/firebase'
import { removeAllControls } from './controlsEx'
import uuidv4 from 'uuid/v4'
import { getData } from '../../data/language'
import NavLinkEx from '../../components/UI/NavLinkEx/NavLinkEx'

export function saveContext() {

    return dispatch => {

        const firebase = getFirebase()

        const deletePromise = firebase.database().ref().child('controls-original').remove()

        Promise.all([deletePromise])

        firebase.database().ref('controls').once('value', (childSnapshot, prevChildKey) => {

            const result = childSnapshot.val()

            if (result === null) {
                return
            }

            Object.keys(result).every(key => {

                firebase.database().ref().child('controls-original').push(result[key], res => {

                })

                return true
            })
        })
    }
}

export function restoreContext(onStart, onFinish) {

    return dispatch => {

        onStart()

        const firebase = getFirebase()
        const deletePromise = firebase.database().ref('controls').remove()

        Promise.all([deletePromise])

        const promises = restoreContextInner()

        Promise.all([promises])

        dispatch(removeAllControls())

        onFinish()
    }
}

function restoreContextInner() {

    const promises = []
    const firebase = getFirebase()

    firebase.database().ref('controls-original').once('value', (childSnapshot, prevChildKey) => {

        const result = childSnapshot.val()

        if (result === null) {
            return
        }

        Object.keys(result).every(key => {

            const promise = firebase.database().ref().child('controls').push(result[key])
            promises.push(promise)

            return true
        })
    })

    return promises
}

export function redirect(path) {

        const history = createBrowserHistory()

        const url = getLink(path)
        /*
        if (history.location.pathname === url) {
            return
        }
        */
    
        return {
            type: REDIRECT,
            payload: url
        }
}

export function getLink(path) {

    const history = createBrowserHistory()

    const regExp = new RegExp('\/(?<language>ru|en)');
    const matchResult = regExp.exec(history.location.pathname)

    let url = '/ru'

    if (matchResult !== null) {
        url = '/' + matchResult.groups.language + ((path === null || path === undefined || path === '/') ? '' : path)
    } else {
        url += ((path === null || path === undefined || path === '/') ? '' : path)
    }

    return url
}

export function resetRedirect() {

    return {
        type: RESET_REDIRECT
    }
}

export function getBool(val) {
    return JSON.parse(String(val).toLowerCase());
}
/*
export const deepCopy = (object, callback) => {
    return Object.keys(object).reduce(function (output, key) {
        output[key] = callback.call(this, object[key]);
        return output;
    }, {});
}
*/

export function deepCopy(obj, parentObj = null, isCopyForLocalStore = false, prevKey = null) {

    if (typeof obj !== 'object' || obj === null) {
        return obj
    }

    if (obj instanceof Date) {
        return new Date(obj.getTime());
    }

    if (obj instanceof Array) {
        return obj.reduce((arr, item, i) => {
            arr[i] = deepCopy(item, parentObj, isCopyForLocalStore, prevKey)
            return arr
        }, []);
    }

    if (obj instanceof Object) {
        if (obj !== parentObj && prevKey !== 'serverStateRaw') {
            obj.parent = parentObj
        }

        return Object.keys(obj).reduce((newObj, key) => {

            if (key === 'parent') {
                newObj[key] = obj[key]
                return newObj
            }

            if (isCopyForLocalStore) {

                if (obj.dataKey === 'about-list-2') {
                    //debugger
                }

                if ((obj['serverState'] !== undefined || obj['serverState'] !== null) && obj['id'] === undefined) {
                    // Complex object (with serverState)

                    obj['id'] = uuidv4()
                } else if ((obj['type'] !== undefined || obj['type'] !== null) && obj['id'] === undefined) {
                    // Object without serverState

                    obj['id'] = uuidv4()
                }

                if (obj['type'] === 'text' && key !== 'type' && typeof (obj[key]) === 'string' && !(obj[key] instanceof Array)) {
                    // Tags parsing requried

                    const parseResult = parseTags(obj[key], key)
                    newObj = typeof (parseResult) === 'string' ? { ...newObj, [key]: parseResult } : { ...newObj, ...parseResult, parent: obj }
                    return newObj
                }
            }

            newObj[key] = deepCopy(obj[key], obj, isCopyForLocalStore, key)

            return newObj;
        }, {})
    }
}

export function deepCopyEx(obj) {

    let result = obj

    if (typeof obj !== 'object' || obj === null) {
        return result
    }

    if (obj instanceof Date) {
        return new Date(obj.getTime());
    }

    if (obj instanceof Array) {
        return obj.reduce((arr, item, i) => {
            arr[i] = deepCopyEx(item)
            return arr
        }, []);
    }

    let newObject = Object.assign(Object.create(Object.getPrototypeOf(obj)), obj)

    if (obj instanceof Object) {

        result = Object.keys(obj).reduce((newObj, key) => {

            if (key === 'parent') {
                newObj[key] = obj[key]
                return newObj
            }

            newObj[key] = deepCopyEx(obj[key])

            return newObj;
        }, newObject)
    }

    return result
}

export function deepCopyEx2(obj, shallow = ['parent', 'serverStateRaw'], from = 'serverState', to = 'localState') {

    let result = obj

    if (typeof obj !== 'object' || obj === null) {
        return result
    }

    if (obj instanceof Date) {
        return new Date(obj.getTime());
    }

    if (obj instanceof Array) {
        return obj.reduce((arr, item, i) => {
            arr[i] = deepCopyEx2(item, shallow, from, to)
            return arr
        }, []);
    }

    let newObject = Object.assign(Object.create(Object.getPrototypeOf(obj)), obj)

    if (obj instanceof Object) {

        result = Object.keys(obj).reduce((newObj, key) => {

            if (shallow.includes(key)) {
                newObj[key] = obj[key]
                return newObj
            }

            if (key === to) {
                return newObj
            }

            if (key === from) {
                newObj[to] = deepCopyEx2(obj[key], shallow, from, to)
                return newObj
            }

            newObj[key] = deepCopyEx2(obj[key], shallow, from, to)

            return newObj;
        }, newObject)
    }

    return result
}

export function deepFind(obj, keyToFind = null, valueToFind = 'any') {

    let result = null

    if (typeof obj !== 'object' || obj === null) {
        return result
    }

    if (obj.ref === 'about-list-1-data-1' && keyToFind === 'ref') {
        // debugger
    }

    if (obj instanceof Date) {
        return result
    }

    if (obj instanceof Array) {
        obj.every(key => {
            result = deepFind(obj[key], keyToFind, valueToFind)
            if (result !== null) {
                return false
            }

            return true
        });
    }

    if (obj instanceof Object) {

        Object.keys(obj).every(key => {

            if (key === 'parent') {
                return true
            }

            const isKeyValueFound = valueToFind === 'any' ? (key === keyToFind) : (key === keyToFind && obj[key] === valueToFind)

            if (isKeyValueFound) {
                result = obj
                return false
            }

            /*
            var count = (new Error().stack.match(/\n/g) || []).length;
            if (count > 100) {
                debugger
            }
            */

            result = deepFind(obj[key], keyToFind, valueToFind)

            if (result !== null) {
                return false
            }

            return true
        })
    }

    return result
}

export function deepFindEx(obj, keyToFind = null, valueToFind = 'any', propertiesToIgnore = ['parent', 'serverStateRaw']) {

    //let deepFindResult = null
    let result = null

    if (typeof obj !== 'object' || obj === null) {
        return result
    }

    if (obj.ref === 'about-list-1-data-1' && keyToFind === 'ref') {
        // debugger
    }

    if (obj instanceof Date) {
        return result
    }

    if (obj instanceof Array) {
        obj.every(key => {
            result = deepFindEx(obj[key], keyToFind, valueToFind, propertiesToIgnore)
            if (result !== null) {
                return false
            }

            return true
        });
    }

    if (obj instanceof Object) {

        Object.keys(obj).every(key => {

            if (propertiesToIgnore.includes(key)) {
                return true
            }

            const isKeyValueFound = valueToFind === 'any' ? (key === keyToFind) : (key === keyToFind && obj[key] === valueToFind)

            if (isKeyValueFound) {
                result = obj
                return false
            }

            result = deepFindEx(obj[key], keyToFind, valueToFind, propertiesToIgnore)

            if (result !== null) {

                if (obj.dataKey === 'about-list-1-data-3') {
                    // debugger
                }

                return false
            }

            return true
        })
    }

    return result
}

export function deepReplace(obj, keyToFind = null, valueToFind = 'any', control, prevKey = null) {

    let result = null

    if (typeof obj !== 'object' || obj === null) {
        return result
    }

    if (obj.ref === 'about-list-1-data-1') {
        // debugger
    }

    if (obj instanceof Date) {
        return result
    }

    if (obj instanceof Array) {
        obj.every((key, i) => {
            const arrayItem = deepReplace(obj[key], keyToFind, valueToFind, control, prevKey)
            if (arrayItem !== null && prevKey !== 'serverStateRaw') {
                obj[i] = deepCopy(arrayItem)
                result = obj[i]
                return false
            }

            return true
        });
    }

    if (obj instanceof Object) {

        Object.keys(obj).every(key => {

            if (key === 'parent') {
                return true
            }

            const isKeyValueFound = valueToFind === 'any' ? (key === keyToFind) : (key === keyToFind && obj[key] === valueToFind)

            if (isKeyValueFound) {
                obj = deepCopy(control)
                result = obj
                return false
            }

            /*
            var count = (new Error().stack.match(/\n/g) || []).length;
            if (count > 100) {
                debugger
            }
            */

            const temp = deepReplace(obj[key], keyToFind, valueToFind, control, key)

            if (temp !== null && prevKey !== 'serverStateRaw') {
                obj[key] = temp
                return false
            }

            return true
        })
    }

    return result
}

export function deepReplaceEx(obj, control, keyToFind = null, valueToFind = 'any', propertiesToIgnore = ['parent', 'serverStateRaw']) {

    let result = null

    if (typeof obj !== 'object' || obj === null) {
        return result
    }

    if (obj.ref === 'about-list-1-data-1') {
        // debugger
    }

    if (obj instanceof Date) {
        return result
    }

    if (obj instanceof Array) {
        obj.every((key, i) => {
            const temp = deepReplaceEx(obj[key], keyToFind, valueToFind, control, propertiesToIgnore)
            if (temp !== null) {
                //obj[i] = deepCopyEx(temp)
                obj[i] = temp
                result = obj[i]
                return false
            }

            return true
        });
    }

    if (obj.dataKey === 'about-list=1') {
        debugger    
    }

    if (obj instanceof Object) {

        Object.keys(obj).every(key => {

            if (propertiesToIgnore.includes(key)) {
                return true
            }

            const isKeyValueFound = valueToFind === 'any' ? (key === keyToFind) : (key === keyToFind && obj[key] === valueToFind)

            if (isKeyValueFound) {
                //result = deepCopyEx(control)
                result = control
                return false
            }

            /*
            var count = (new Error().stack.match(/\n/g) || []).length;
            if (count > 100) {
                debugger
            }
            */

            const temp = deepReplaceEx(obj[key], keyToFind, valueToFind, control, propertiesToIgnore)

            if (temp !== null) {
                //obj[key] = deepCopyEx(temp)
                obj[key] = temp
                // result = obj[key]
                return false
            }

            return true
        })
    }

    return result
}

export function findRefs(obj, result = [], path = [], key = '') {
    if (typeof obj !== 'object' || obj === null || key === 'parent') {
        return
    }

    if (obj instanceof Date) {
        return
    }

    if (obj instanceof Array) {

        const newPath = [...path]
        newPath.push(key)

        return obj.forEach((item, i) => {

            findRefs(item, result, newPath, i)
        });
    }

    if (obj instanceof Object) {

        return Object.keys(obj).forEach((propertyName, i) => {

            if (propertyName === 'ref') {

                const newPath = [...path]
                newPath.push(key)
                result.push({ ref: obj.ref, path: newPath })

                return false
            }

            findRefs(obj[propertyName], result, path, propertyName)
        })
    }
}

export function checkRefs(control) {
    const refs = []
    findRefs(control.serverState, refs)
    return refs.length > 0
}

export function getRoot(control = null) {

    if (control === null || control.parent === undefined || control.parent === null) {
        return control
    }

    return getRoot(control.parent)
}

export function isClickInControl(e, control) {

    let result = false

    e.path.every(item => {
        if (item === control) {
            result = true
            return false
        }

        return true
    })

    return result
}

export function parseTags(text, key) {

    if (!(typeof(text) === 'string')) {
        return text
    }

    if (text === undefined) {
        debugger
    }

    if (text === '[a=/maps/center|x2 - Center (PvE)]. Custom map 4096x4096. Start date 12.08.2018') {
        // debugger
    }

    const parsedTagsArray = parseTagsInner(text)

    if (parsedTagsArray === null) {
        return text
    }

    const newKeyName = `${key}-parsed-tags`
    const result = { [newKeyName]: parsedTagsArray }
    
    return result
}

export function parseTagsEx(text) {

    if (!(typeof text === 'string')) {
        return text
    }

    if (text === undefined) {
        debugger
    }

    if (text === '[a=/maps/center|x2 - Center (PvE)]. Custom map 4096x4096. Start date 12.08.2018') {
        // debugger
    }

    const result = parseTagsInner(text)

    if (result === null) {
        return text
    }

    return result
}


function getDataWithTagsInner(obj, index, isNoWrappers) {

    let result = null

    if (typeof (obj) === 'string') {
        result = isNoWrappers ?
            obj
            :
            <React.Fragment key={index}>
                {getStringWithBreaks(obj)}
            </React.Fragment>
    } else if (obj instanceof Object && obj.type === 'tag') {

        switch (obj.tag) {
            case 'a':
                const link = { to: obj.data.to, label: obj.data.label }
                result = <NavLinkEx link={link} key={index} />
                break;
        }
    }

    return result
}

export function getDataWithTags(textWithTagsArray, isNoWrappers) {

    const result = textWithTagsArray.map((item, index) => {
        return getDataWithTagsInner(item, index, isNoWrappers)
    })

    return result
}

export function getByTopic(topic, language) {
    const data = getData()

    if (data[topic] === undefined) {
        return 'No translation found'
    }

    const dataItem = data[topic]

    return dataItem[language]
}

export function getText(key, isNoWrappers = true) {

    return (dispatch, getState) => {
        const state = getState().globals
        const data = state.controls[key]
        const language = state.language
        return getByData(data, language, isNoWrappers)
    }
}

export function getTextEx(key, state, isNoWrappers = true) {

    const data = state.globals.controls[key]

    if (!!!data) {
        return null
    }

    const language = state.globals.language

    return getByData(data, language, isNoWrappers)
}

function parseTagsInner(text) {

    let result = null
    const tagParts = []
    const textWithoutTags = text.replace(/\[(?<tag>\w{1})=(([^|]+\])|((?<data>.+?)\|(?<title>.+?)\]))/gi, (...args) => parseTag(args[args.length - 1], tagParts))

    if (tagParts.length > 0) {
        const textParts = textWithoutTags.split('[replacement]')
        result = []

        textParts.forEach(item => {
            if (item.length > 0) {
                result.push(item)
            }

            if (tagParts.length > 0) {
                result.push(tagParts.shift())
            }
        });
    }

    return result
}

function parseTag(group, tagParts) {

    let result = null

    switch (group.tag) {
        case 'a':
            const link = { to: group.data, label: group.title }
            const tagObj = { type: 'tag', tag: 'a', data: link }
            tagParts.push(tagObj)
            result = '[replacement]'
            break;
    }

    return result
}

function getStringWithBreaks(data) {
    if (typeof (data) !== 'string' || !data.includes('\n')) {
        return data
    }

    const result = []
    const tempArray = data.split('\n')
    let counter = 1;

    tempArray.every((item, i) => {
        result.push(<React.Fragment key={counter}>{item}</React.Fragment>)
        counter++
        result.push(<br key={counter} />)
        counter++

        return true
    })

    result.pop()

    return result
}

export function getByData(data, language, isNoWrappers) {

    const key = data['text'] !== undefined ? 'text' : language

    if (isNoWrappers) {
        return data[key]
    }

    const tagsKeyName = `${key}-parsed-tags`

    if (data[tagsKeyName] === undefined) {
        return <React.Fragment key={tagsKeyName}>
            {getStringWithBreaks(data[key])}
        </React.Fragment>
    }

    const tags = getDataWithTags(data[tagsKeyName], isNoWrappers)

    const result = (
        <React.Fragment>
            {tags}
        </React.Fragment>
    )

    return result
}