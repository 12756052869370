import React, { Component } from 'react'
import { connect } from 'react-redux'
import classes from './AddNewItem.css'
import Edit from '../Edit/Edit'
import MultiEdit from '../Edit/MultiEdit/MultiEdit'
import { getControlFromState, updateControlState, addData } from '../../../redux/actions/controlsEx'
import { withRouter } from 'react-router-dom'
import ComplexControl from '../../../data/ComplexControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

class AddNewItem extends Component {

    constructor(props) {
        super(props)
        this.wrapperRef = React.createRef()
    }

    startEditHandler() {
        this.props.control.flags.childInEditId = 'new'
        this.props.control.flags.isEditEnabled = true
        this.props.updateControlState(this.props.control, this.props.rootControl)
    }

    applyChangesHandler = data => {

        this.props.addData(this.props.control, this.props.rootControl, data)
    }

    cancelChangesHandler = () => {

        this.props.control.flags.childInEditId = null
        this.props.control.flags.isEditEnabled = false
    }

    render() {

        if (!this.props.isEditGlobal || !!!this.props.control || !!!this.props.control.localState.dataTemplate) {
            return null
        }

        const template = this.props.control instanceof ComplexControl ? this.props.control.localState.dataTemplate : this.props.control.dataTemplate

        let editControl = null

        if (!this.props.control.flags.isEditEnabled) {
            editControl = null
        } else if (template.type === 'text-array' && this.props.control.flags.childInEditId === 'new') {
            editControl =
                <MultiEdit
                    data={this.props.control}
                    onCancel={() => this.cancelChangesHandler()}
                    isNew={true}
                />
        } else if (template.type === 'text' && this.props.control.flags.childInEditId === 'new') {
            editControl =
                <MultiEdit
                    data={this.props.control}
                    onCancel={() => this.cancelChangesHandler()}
                    isNew={true}
                />
        }

        return (
                <div ref={this.wrapperRef}>
                    <div className={classes.AddNewItem} onClick={() => this.startEditHandler()}>
                        <FontAwesomeIcon icon={faPlus} className={classes.AddButton} fixedWidth size="1x" /> <span className={classes.ButtonTitle}>Add</span>
                    </div>
                    {editControl}
                </div>
            )
    }
}

function mapStateToProps(state, initialProps) {
    const controlId = initialProps.data !== undefined ? initialProps.data.id : initialProps.id === undefined ? null : initialProps.id
    const controlFromState = getControlFromState(state.controls, initialProps.dataKey, controlId, 'local')
    return {
        isEditGlobal: state.edit.isEditModeActive,
        control: controlFromState.control === undefined ? null : controlFromState.control,
        parentControl: controlFromState.control !== null && controlFromState.control.parent !== undefined ? controlFromState.control.parent : null,
        rootControl: controlFromState.rootControl
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateControlState: (control, rootControl) => dispatch(updateControlState(control, rootControl)),
        addData: (control, rootControl, data) => dispatch(addData(control, rootControl, data))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddNewItem))