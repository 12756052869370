import { SET_REFERENCE_TREE, SAVE_REFERENCE } from '../actions/actionTypes'

const initialState = {
    controls: {}
}

export default function referenceReducer(state = initialState, action) {

    switch (action.type) {
        case SAVE_REFERENCE:
            return {
                ...state,
                controls: action.payload
            }
        default:
            return state
    }
}