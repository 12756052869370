import { deepCopyEx } from '../redux/actions/general'

function change(originalValue, value, isAnyFlagChanged) {
    if (value !== null && value !== originalValue) {
        isAnyFlagChanged.result = true
        return value
    }
    return originalValue
}

class ControlFlags {

    constructor(data = null) {
        if (data === null) {
            return
        }

        this.updateEx(data)
    }

    isEditEnabled = false
    isChildEditEnabled = false
    isLoading = false
    isChanged = false
    childInEditId = null

    updateEx = data => {

        Object.keys(this).every(property => {

            if (typeof this[property] === 'function') {
                return true
            }

            if (data[property] !== undefined) {
                this[property] = deepCopyEx(data[property])
            }

            return true
        })
    }

    update = (isEditEnabled = null, isLoading = null, isChanged = null) => {

        let isAnyFlagChanged = { result: false }

        if (isEditEnabled !== null && isEditEnabled !== this.isEditEnabled) {
            isAnyFlagChanged.result = true
            this.isEditEnabled = true
        }

        //this.isEditEnabled = change(this.isEditEnabled, isEditEnabled, isAnyFlagChanged) 
        this.isLoading = change(this.isLoading, isLoading, isAnyFlagChanged)
        this.isChanged = change(this.isChanged, isChanged, isAnyFlagChanged)

        return isAnyFlagChanged.result
    }
}

export default ControlFlags