import { BasicControl, ComplexControl } from './index'
import { createBasicControls, createBasicControlsEx } from '../redux/actions/controlsEx'

class ControlFactory {

    static create = (data, controlId, parent, serverId) => {

        let result = null

        if (data.type === 'text') {
            result = new BasicControl(data, controlId, parent)
        } else if (data.type !== undefined && data.type !== null && data.type !== 'tag') {
            result = new ComplexControl(data, controlId, parent, serverId)
            createBasicControls(result)
        }

        return result
    }

    static copy = (data, isNewIdRequired = false) => {
        let result = null
        const id = isNewIdRequired ? null : data.id

        if (data instanceof BasicControl) {
            result = new BasicControl(data, id, data.parent, data.parentKey)
            //debugger
            createBasicControlsEx(result, isNewIdRequired)
        } else if (data instanceof ComplexControl) {
            result = new ComplexControl(data, id, data.parent, data.parentKey)
            createBasicControlsEx(result, isNewIdRequired)
        }

        return result
    }
}

export default ControlFactory