export function getData() {
    return {
        About: { ru: 'О нас', en: 'About' },
        Details: { ru: 'Характеристики', en: 'Details' },
        News: { ru: 'Новости', en: 'News' },
        Rules: { ru: 'Правила', en: 'Rules' },
        Maps: { ru: 'Карты', en: 'Maps' },
        X2CenterPvE: { ru: 'x2 - Центр (PvE)', en: 'x2 - Center (PvE)' },
        X2NorthPvP: { ru: 'x2 - Север (PvP)', en: 'x2 - North (PvP)' },
        Admin: { ru: 'Админ', en: 'Admin' },
        ServerStarted: { ru: 'Запущен', en: 'Started' },
        Loading: { ru: 'Загрузка', en: 'Loading' },

        GlobalControls: {
            'about': { ru: 'О нас', en: 'About', type: 'text' },
            'details': { ru: 'Характеристики', en: 'Details', type: 'text' },
            'news': { ru: 'Новости', en: 'News', type: 'text' },
            'rules': { ru: 'Правила', en: 'Rules', type: 'text' },
            'maps': { ru: 'Карты', en: 'Maps', type: 'text' },
            'admin': { ru: 'Админ', en: 'Admin', type: 'text' },
            'loading': { ru: 'Загрузка', en: 'Loading', type: 'text' },
            'x2-center-pve': { ru: 'x2 - Центр (PvE)', en: 'x2 - Center (PvE)', type: 'text' },
            'x2-north-pvp': { ru: 'x2 - Север (PvP)', en: 'x2 - North (PvP)', type: 'text' },
            'restore-context': { ru: 'Восстановить веб-сайт', en: 'Restore website', type: 'text' },
            'restore-context-notice': { ru: 'Данный веб-сайт является демо версией. Вы можете изменять контент веб-сайта, используя \'Режим редактирования\'.\n По завершении своей работы, пожалуйста, кликните кнопку \'Восстановить веб-сайт\', чтобы отменить свои изменения и восстановить оригинальный контент.', en: 'This is a demo version of the website. You are free to use Edit Mode to change content.\n But once you are done, please click the button above to revert your changes and restore the original content.', type: 'text' },
            'administration-panel': { ru: 'Панель администрирования', en: 'Administration panel', type: 'text' },
            'edit-mode': { ru: 'Режим редактирования', en: 'Edit mode', type: 'text' },
            'join-discord': { ru: 'Присоединяйтесь к нам в Дискорде', en: 'Join Us in Discord', type: 'text' },
            'accept': { ru: 'Применить', en: 'Ok', type: 'text' },
            'cancel': { ru: 'Отмена', en: 'Cancel', type: 'text' },
            'russian': { ru: 'Русский', en: 'Russian', type: 'text' },
            'english': { ru: 'Английский', en: 'English', type: 'text' },
            'delete': { ru: 'Удалить', en: 'Delete', type: 'text' },
            'save-changes': { ru: 'Сохранить изменения', en: 'Save changes', type: 'text' },
            'cancel-changes': { ru: 'Отменить изменения', en: 'Cancel changes', type: 'text' },
            'modified-title': { ru: 'Данный элемент веб-сайта был изменен, но не сохранен. Вы можете потерять изменения при перезагрузке страницы', en: 'This part of website is modified but not saved. You can loose your changes upon page refresh', type: 'text' },
            'modified': { ru: 'Изменения', en: 'Modified', type: 'text' },
            'loading': { ru: 'Загрузка...', en: 'Loading...', type: 'text' },
            'logout': { ru: 'Выйти', en: 'Logout', type: 'text' },
            'all-languages': { ru: 'Все языки', en: 'All languages', type: 'text' },
            'save-context': { ru: 'Сохранить веб-сайт', en: 'Save website', type: 'text' },
            'empty-warning': { ru: 'Пожалуйста введите текст', en: 'Value cannot be empty', type: 'text' }
        },

        AboutList2: {
            id: 'AboutList2',
            title: { ru: 'Основные характеристики', en: 'Major featuers' },
            list: [
                { ru: 'В PvE мире имеется стартовый город, позволяющий новичкам легко освоиться в игре', en: 'We have a starter city on PvE server which provides easy start for new players' },
                { ru: 'Порталы, ведущие из стартового города в ключевые места карты', en: 'There you can find portals which can teleport you to all key locations of the map' },
                { ru: 'PvE режим работы позволяет сосушествовать профессиональным и только начинающим игрокам', en: 'PvE mode allows professional players and newcomers peacefully coexist' },
                { ru: 'Бесплатное создание поселения - установить свое собственное поселение можно бесплатно. Однако плата за содержание поселения взымается', en: 'There is no deed creation fee - you can start your own settlement for free. But there are settlement support fees' },
                { ru: 'Администрация активно работает над улучшением качества жизни игроков. Помимо внутриигровой поддержки имеется возможность отправить запрос администрации по e-mail через наш веб сайт', en: 'Server administration is actively working on improving players game experience. We accept support requests from players ingame, on our discord, through email or directly from website' },
                { ru: 'Имеется дискорд канал синхронизированный с основным внутриигровым чатом. Все сообщения, написанные в дискорде, появляются в игре. И наоборот, всю переписку игрового чата можно увидеть в дискорде', en: 'Our discord is synchronized with ingame chat. You can see all ingame chat messages in discord and vice versa' },
                { ru: 'Сервер улучшен большим количеством МОДов', en: 'Server is enhanced by large ammount of MODs' },
                { ru: 'Присоединяйтесь к нам в Дискорде', en: 'Join us in Discord' }
            ]
        },

        'news-list': {
            dataKey: 'news-list',
            type: 'list',
            style: 'square',
            title: { type: 'text', ru: 'Новости', en: 'News' },
            dataGrow: 'top',
            dataTemplate: {
                type: 'text-array',
                dataTemplate: [
                    { type: 'text', subType: 'date', text: '', removable: false, bold: true, separator: ' - ' },
                    { type: 'text', ru: '', en: '' }
                ]
            },
            data: [
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', subType: 'date', text: '15.08.2018', removable: false, bold: true, separator: ' - ' },
                        { type: 'text', ru: 'Запущен [a=/maps/north|x2 - Север (PvP)]', en: 'Started [a=/maps/north|x2 - North (PvP)]' }
                    ]
                },
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', subType: 'date', text: '12.08.2018', removable: false, bold: true, separator: ' - ' },
                        { type: 'text', ru: 'Запущен [a=/maps/center|x2 - Центр (PvE)]', en: 'Started [a=/maps/center|x2 - Center (PvE)]' }
                    ]
                }
            ]
        },

        'about-list-2': {
            dataKey: 'about-list-2',
            type: 'list',
            layout: 'column',
            style: 'square',
            title: { ru: 'Основные характеристики', en: 'Major featuers' },
            data: [
                { type: 'text', ru: 'В PvE мире имеется стартовый город, позволяющий новичкам легко освоиться в игре', en: 'We have a starter city on PvE server which provides easy start for new players' },
                { type: 'text', ru: 'Порталы, ведущие из стартового города в ключевые места карты', en: 'There you can find portals which can teleport you to all key locations of the map' },
                { type: 'text', ru: 'PvE режим работы позволяет сосушествовать профессиональным и только начинающим игрокам', en: 'PvE mode allows professional players and newcomers peacefully coexist' },
                { type: 'text', ru: 'Бесплатное создание поселения - установить свое собственное поселение можно бесплатно. Однако плата за содержание поселения взымается', en: 'There is no deed creation fee - you can start your own settlement for free. But there are settlement support fees' },
                { type: 'text', ru: 'Администрация активно работает над улучшением качества жизни игроков. Помимо внутриигровой поддержки имеется возможность отправить запрос администрации по e-mail через наш веб сайт', en: 'Server administration is actively working on improving players game experience. We accept support requests from players ingame, on our discord, through email or directly from website' },
                { type: 'text', ru: 'Имеется дискорд канал синхронизированный с основным внутриигровым чатом. Все сообщения, написанные в дискорде, появляются в игре. И наоборот, всю переписку игрового чата можно увидеть в дискорде', en: 'Our discord is synchronized with ingame chat. You can see all ingame chat messages in discord and vice versa' },
                { type: 'text', ru: 'Сервер улучшен большим количеством МОДов', en: 'Server is enhanced by large ammount of MODs' },
                { type: 'text', ru: 'Присоединяйтесь к нам в Дискорде', en: 'Join us in Discord' }
            ]
        },

        'about-list-1': {
            dataKey: 'about-list-1',
            type: 'div',
            layout: 'column',
            style: 'none',
            title: { ru: 'О нас', en: 'About' },
            data: [
                { ref: 'about-list-1-data-1' },
                { ref: 'about-list-1-data-2' },
                { ref: 'about-list-1-data-3' }
            ]
        },
        'about-list-1-data-1': {
            dataKey: 'about-list-1-data-1',
            type: 'div',
            layout: 'column',
            style: 'none',
            dataTemplate: { type: 'text', ru: '', en: '' },
            data: [
                { type: 'text', ru: 'Silver Blades является серверным провайдером игры Wurm Unlimited', en: 'Silver Blades is a server provider for the game Wurm Unlimited' },
                { type: 'text', ru: 'Мы поддерживаем кластер состящий из 2 связанных Wurm Unlimited серверов:', en: 'We support cluster of 2 linked Wurm Unlimited servers:' },
            ]
        },
        'about-list-1-data-2': {
            dataKey: 'about-list-1-data-2',
            type: 'list',
            style: 'square',
            dataTemplate: { type: 'text', ru: '', en: '' },
            data: [
                { type: 'text', ru: '[a=/maps/center|x2 - Центр (PvE)]. Уникальная карта 4096x4096. Дата начала работы 12.08.2018', en: '[a=/maps/center|x2 - Center (PvE)]. Custom map 4096x4096. Start date 12.08.2018' },
                { type: 'text', ru: '[a=/maps/north|x2 - Север (PvP)]. Уникальная карта 4096x4096. Дата начала работы 15.08.2018', en: '[a=/maps/north|x2 - North (PvP)]. Custom map 4096x4096. Start date 15.08.2018' }
            ]
        },
        'about-list-1-data-3': {
            dataKey: 'about-list-1-data-3',
            type: 'div',
            layout: 'column',
            style: 'none',
            dataTemplate: { type: 'text', ru: '', en: '' },
            data: [
                { type: 'text', ru: 'Наш хостинг расположен в современном дата центре в Москве', en: 'We host our servers in one of the best data centers in Moscow (Russia)' },
                { type: 'text', ru: 'Добро пожаловать и удачной игры', en: 'Welcome and have a great gameplay' }
            ]
        },

        'rules-list': {
            dataKey: 'rules-list',
            type: 'list',
            layout: 'column',
            style: 'square',
            title: { type: 'text', ru: 'Правила', en: 'Rules' },
            dataTemplate: { type: 'text', ru: '', en: '' },
            data: [
                { type: 'text', ru: 'Общение в оскобительном контексте запрещено. Запрещена нецензурная лексика', en: 'Harassment of other players is not allowed. Using of coarse and offencive language is not allowed' },
                { type: 'text', ru: 'Запрещено закапывать реки и узкие водные перешейки между континентами', en: 'It is not allowed to terraform (fill up with dirt) rivers and narrow water passages between islands' },
                { type: 'text', ru: 'Запрещено использование внутриигровых багов и уязвимостей', en: 'It is not allowed to use ingame bugs to gain ingame advantages' },
                { type: 'text', ru: 'Запрещено использование королевских торговцев на PvE', en: 'It is not allowed to use kingdom traders on PvE server' },
                { type: 'text', ru: 'Запрещено использовать больше трех подключений с одного акканута', en: 'More than 3 simultanious connections from 1 steam account are not allowed' },
                { type: 'text', ru: 'Разрешены клиентские моды. Мы сами используем и рекомендуем установить \'Improved Compass\' мод', en: 'Client mods are allowed. We even recommend to install \'Improved Compas\' mod' },
                { type: 'text', ru: 'Разрешены автокликеры', en: 'Autoclickers are allowed' }
            ]
        },


        'details-list-1': {
            dataKey: 'details-list-1',
            type: 'list',
            layout: 'column',
            style: 'square',
            title: { type: 'text', ru: 'Характеристики сервера', en: 'Server details' },
            dataTemplate: { type: 'text', ru: '', en: '' },
            data: [
                { type: 'text', ru: '[a=/maps/center|PvE] | [a=/maps/north|PvP]', en: '[a=/maps/center|PvE] | [a=/maps/north|PvP]' },
                { type: 'text', ru: 'Максимальное количество игроков 200', en: 'Maximum number of players 200' },
                { type: 'text', ru: '2 карты 4096x4096', en: '2 maps 4096x4096' },
                { type: 'text', ru: 'Множитель роста скиллов x2', en: 'Skills multiplier x2' },
                { type: 'text', ru: 'Множитель скорости умений x4', en: 'Actions multiplier x4' },
                { type: 'text', ru: 'Количество животных 50000 на PvE. 90000 на PvP', en: 'Animal quantity is 50000 на PvE. 90000 на PvP' },
                { type: 'text', ru: '[a=/maps/center|x2 - Центр (PvE)] Бесплатная установка поселений. Плата за поддержку поселения взымается', en: '[a=/maps/center|x2 - Center (PvE)] There is no deed creation fee. Setlement support fees are present' },
                { type: 'text', ru: '[a=/maps/north|x2 - Север (PvP)] Платная установка поселений. Плата за поддержку поселения взымается', en: '[a=/maps/north|x2 - North (PvP)] There is fee fpr deed creation. Setlement support fees are present' },
                { type: 'text', ru: '[a=/maps/north|x2 - Север (PvP)] Время спавна уникальных существ значительно уменьшено', en: '[a=/maps/north|x2 - North (PvP)] Time for spawn of unique creature is significantly reduced' },
                { type: 'text', ru: 'Ограничения у жрецов отсутствуют. Молиться можно без ограничений, но только раз в 20 минут', en: 'Priest restrictions are disabled. You can make prayers without limitations, but just once in 20 minutes' },
                { type: 'text', ru: 'Ограничения на медитацию отсутствуют', en: 'Meditation restrictions are disabled' },
                { type: 'text', ru: 'Увеличена скорость ездовых животных, повозок, кораблей. Самый быстрый наземный маунт - единорог. Самый быстрый корабль - Кнарр, однако при хорошем попутном ветре Каравела может быть быстрее', en: 'Mounts, carts and ships have increased speed. Unicorn is fastest ground mount. Knarr is fastest ship, but with fair wind Caravel can be faster' },
            ]
        },

        'details-list-2': {
            dataKey: 'details-list-2',
            type: 'list',
            layout: 'column',
            style: 'square',
            title: { type: 'text', ru: 'Установленные моды', en: 'MODs list' },
            dataTemplate: {
                type: 'text-array',
                dataTemplate: [
                    { type: 'text', text: '', removable: false, bold: true },
                    { type: 'text', ru: '', en: '' }
                ]
            },
            data: [
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', text: 'Announcer by ago', removable: false, bold: true },
                        { type: 'text', ru: 'Обьявляет о приходе или выходе игрока из игры', en: 'Makes annoucments on player login/logout' }
                    ]
                },
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', text: 'Better Dig by bdew ', removable: false, bold: true },
                        { type: 'text', ru: 'При копании земля автоматически складывается в кучи, минуяя инвентарь игрока. Есть возможность копать, выравнивать землю во время управления повозкой, при этом земля складывается либо в повозку, либо в контейнеры внутри повозки', en: 'When digging earth is automatically placed in stockpiles on the ground, it never goes to player inventory. You can do terraforming while driving a cart. In this case earth is automatically placed into the cart or containers inside of the cart' }
                    ]
                },
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', text: 'Bounty Mod by WalkerInTheVoid', removable: false, bold: true },
                        { type: 'text', ru: 'Игроки получают награды за убийство мобов (монеты и карму)', en: 'Players recieve bounties (coins and karma) for killing mobs' }
                    ]
                },
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', text: 'Bulk Item Seperator', removable: false, bold: true },
                        { type: 'text', ru: 'Позволяет хранить вещи в BSB разного качества раздельно. Данная функция может быть включена/отключена игроком', en: 'Adds option to store items of different quality in the same BSB. This option can be switched on/off by a player' }
                    ]
                },
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', text: 'Crop Mod by ago', removable: false, bold: true },
                        { type: 'text', ru: 'Предотвращает порчу урожая после его созревания', en: 'Disables harvest decay' }
                    ]
                },
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', text: 'Harvest Helper by ago', removable: false, bold: true },
                        { type: 'text', ru: 'Позволяет узнавать точное время сбора урожая и текущий сезон. Используется команада /seasons', en: 'Provides exact harvest time and current season of the year. You can use /seasons command for that' }
                    ]
                },
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', text: 'Hitch Limits by Bdew', removable: false, bold: true },
                        { type: 'text', ru: 'Позволяет запрягать в повозки мобов возраста старый+ и чемпионов', en: 'Allows to hitch mobs of old+ age and champions' }
                    ]
                },
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', text: 'Hot Items to BSB/FSB by WalkerInTheVoid', removable: false, bold: true },
                        { type: 'text', ru: 'Позволяет складывать горячие вещи в BSB/FSB не ожидая пока они остынут', en: 'You can put hot items into BSB/FSB. No need to wait untill they are cold enough anymore' }
                    ]
                },
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', text: 'Increased Merchant Items by ausimus', removable: false, bold: true },
                        { type: 'text', ru: 'Расширяет ассортимент торговцев', en: 'Extends traders inventory' }
                    ]
                },
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', text: 'Meditate Mod by WalkerInTheVoid', removable: false, bold: true },
                        { type: 'text', ru: 'Убирает время ожидания для получения следующего уровня медитации', en: 'Disables delay between meditation levels' }
                    ]
                },
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', text: 'Salve Descripton Mod by WalkerInTheVoid', removable: false, bold: true },
                        { type: 'text', ru: 'Добавляет описание для лечебных повязок, сообщающее \'силу\' повязки', en: 'Adds description of healing covers which tells you its strength' }
                    ]
                },
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', text: 'Treasure Hunting by Ulviirala', removable: false, bold: true },
                        { type: 'text', ru: 'Позволяет находить карты сокровищ при совершении некоторых действий. С помощью карты можно найти клад определенной ценности и охранника(ков)', en: 'Alows you to find treasure maps during some actions. With such map it is possible to find some treasure and some guards' }
                    ]
                },
                {
                    type: 'text-array',
                    data: [
                        { type: 'text', text: 'LocationCommand', removable: false, bold: true },
                        { type: 'text', ru: 'Позволяет узнать свои координаты, введя команду /location', en: 'You can use /location command to determine your current coordinates' }
                    ]
                }
            ]
        },

        RulesText1: { ru: 'Общение в оскобительном контексте запрещено. Запрещена нецензурная лексика', en: 'Harassment of other players is not allowed. Using of coarse and offencive language is not allowed' },
        RulesText2: { ru: 'Запрещено закапывать реки и узкие водные перешейки между континентами', en: 'It is not allowed to terraform (fill up with dirt) rivers and narrow water passages between islands' },
        RulesText3: { ru: 'Запрещено использование внутриигровых багов и уязвимостей', en: 'It is not allowed to use ingame bugs to gain ingame advantages' },
        RulesText4: { ru: 'Запрещено использование королевских торговцев на PvE', en: 'It is not allowed to use kingdom traders on PvE server' },
        RulesText5: { ru: 'Запрещено использовать больше трех подключений с одного акканута', en: 'More than 3 simultanious connections from 1 steam account are not allowed' },
        RulesText6: { ru: 'Разрешены клиентские моды. Мы сами используем и рекомендуем установить \'Improved Compass\' мод', en: 'Client mods are allowed. We even recommend to install \'Improved Compas\' mod' },
        RulesText7: { ru: 'Разрешены автокликеры', en: 'Autoclickers are allowed' },

        AboutText7: { ru: 'Основные характеристики', en: 'Major featuers' },

        DetailsText1:  { ru: 'Характеристики сервера', en: 'Server details' },
        DetailsText2:  { ru: 'Максимальное количество игроков 200', en: 'Maximum number of players 200' },
        DetailsText3:  { ru: '2 карты 4096x4096', en: '2 maps 4096x4096' },
        DetailsText4:  { ru: 'Множитель роста скиллов x2', en: 'Skills multiplier x2' },
        DetailsText5:  { ru: 'Множитель скорости умений x4', en: 'Actions multiplier x4' },
        DetailsText6:  { ru: 'Количество животных 50000 на PvE. 90000 на PvP', en: 'Animal quantity is 50000 на PvE. 90000 на PvP' },
        DetailsText7:  { ru: 'Бесплатная установка поселений. Плата за поддержку поселения взымается', en: 'There is no deed creation fee. Setlement support fees are present' },
        DetailsText8:  { ru: 'Платная установка поселений. Плата за поддержку поселения взымается', en: 'There is fee fpr deed creation. Setlement support fees are present' },
        DetailsText9:  { ru: 'Время спавна уникальных существ значительно уменьшено', en: 'Time for spawn of unique creature is significantly reduced' },
        DetailsText10: { ru: 'Ограничения у жрецов отсутствуют. Молиться можно без ограничений, но только раз в 20 минут', en: 'Priest restrictions are disabled. You can make prayers without limitations, but just once in 20 minutes' },
        DetailsText11: { ru: 'Ограничения на медитацию отсутствуют', en: 'Meditation restrictions are disabled' },
        DetailsText12: { ru: 'Увеличена скорость ездовых животных, повозок, кораблей. Самый быстрый наземный маунт - единорог. Самый быстрый корабль - Кнарр, однако при хорошем попутном ветре Каравела может быть быстрее', en: 'Mounts, carts and ships have increased speed. Unicorn is fastest ground mount. Knarr is fastest ship, but with fair wind Caravel can be faster' },


        DetailsText13: { ru: 'Установленные моды', en: 'MODs list' },
        DetailsText14: { ru: 'Обьявляет о приходе или выходе игрока из игры', en: 'Makes annoucments on player login/logout' },
        DetailsText15: { ru: 'При копании земля автоматически складывается в кучи, минуяя инвентарь игрока. Есть возможность копать, выравнивать землю во время управления повозкой, при этом земля складывается либо в повозку, либо в контейнеры внутри повозки', en: 'When digging earth is automatically placed in stockpiles on the ground, it never goes to player inventory. You can do terraforming while driving a cart. In this case earth is automatically placed into the cart or containers inside of the cart' },
        DetailsText16: { ru: 'Игроки получают награды за убийство мобов (монеты и карму)', en: 'Players recieve bounties (coins and karma) for killing mobs' },
        DetailsText17: { ru: 'Позволяет хранить вещи в BSB разного качества раздельно. Данная функция может быть включена/отключена игроком', en: 'Adds option to store items of different quality in the same BSB. This option can be switched on/off by a player' },
        DetailsText18: { ru: 'Предотвращает порчу урожая после его созревания', en: 'Disables harvest decay' },
        DetailsText19: { ru: 'Позволяет узнавать точное время сбора урожая и текущий сезон. Используется команада /seasons', en: 'Provides exact harvest time and current season of the year. You can use /seasons command for that' },
        DetailsText20: { ru: 'Позволяет запрягать в повозки мобов возраста старый+ и чемпионов', en: 'Allows to hitch mobs of old+ age and champions' },
        DetailsText21: { ru: 'Позволяет складывать горячие вещи в BSB/FSB не ожидая пока они остынут', en: 'You can put hot items into BSB/FSB. No need to wait untill they are cold enough anymore' },
        DetailsText22: { ru: 'Расширяет ассортимент торговцев', en: 'Extends traders inventory' },
        DetailsText23: { ru: 'Убирает время ожидания для получения следующего уровня медитации', en: 'Disables delay between meditation levels' },
        DetailsText24: { ru: 'Добавляет описание для лечебных повязок, сообщающее \'силу\' повязки', en: 'Adds description of healing covers which tells you its strength' },
        DetailsText25: { ru: 'Позволяет находить карты сокровищ при совершении некоторых действий. С помощью карты можно найти клад определенной ценности и охранника(ков)', en: 'Alows you to find treasure maps during some actions. With such map it is possible to find some treasure and some guards' },
        DetailsText26: { ru: 'Позволяет узнать свои координаты, введя команду /location', en: 'You can use /location command to deturmine your current coordinates' }

    }
}
