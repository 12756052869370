import React, { Component } from 'react'
import classes from './Details.css'
import NavLinkEx from '../../components/UI/NavLinkEx/NavLinkEx'
import Separator from '../../components/UI/Separator/Separator'
import Text from '../../components/UI/Text/Text'
import DivEx from '../../components/Controls/DivEx/DivEx'
import uuidv4 from 'uuid/v4'

const detailsList1Id = uuidv4()
const detailsList2Id = uuidv4()

class Details extends Component {

    render() {
        return (
            <div className={classes.Details}>

                <DivEx id={detailsList1Id} dataKey="details-list-1" />

                <DivEx id={detailsList2Id} dataKey="details-list-2" />

            </div>
        )
    }
}

export default Details