import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import classes from './Text.css'
import { save } from '../../../redux/actions/references'
//import { getList, saveFlags, getRoot } from '../../../redux/actions/controls'
import uuidv4 from 'uuid/v4'
import { switchEditMode } from '../../../redux/actions/edit'
import MultiEdit from '../Edit/MultiEdit/MultiEdit'
import { getByData, getByTopic, isClickInControl } from '../../../redux/actions/general'
import { getControlFromState, updateControlState, deleteControl } from '../../../redux/actions/controlsEx'


class Text extends Component {

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
    }

    state = {
        isEditLocal: false
    }

    setFlags(isEditEnabled) {

        let isFlagChanged = false
        const isChildEditEnabled = isEditEnabled

        if (this.props.control.flags.isEditEnabled !== isEditEnabled) {
            this.props.control.flags.isEditEnabled = isEditEnabled
            isFlagChanged = true
        }

        if (this.props.parentControl !== null) {
            if (isChildEditEnabled) {
                this.props.parentControl.flags.isChildEditEnabled = true
                this.props.parentControl.flags.childInEditId = this.props.control.id
            } else {
                if (this.props.parentControl.flags.childInEditId === this.props.control.id) {
                    this.props.parentControl.flags.isChildEditEnabled = false
                    this.props.parentControl.flags.childInEditId = null
                }
            }
        }

        return isFlagChanged
    }

    onClick = e => {

        if (this.wrapperRef.current === null) {
            this.cancelEditHandler()
            return
        }

        const isCancelRequired = !isClickInControl(e, this.wrapperRef.current) //!this.wrapperRef.current.contains(e.target)

        if (isCancelRequired) {
            this.cancelEditHandler()
        }
    }

    startEditHandler() {
        if (!this.props.isEditGlobal || this.props.control === null || this.props.control.flags === undefined || this.props.control.flags.isEditEnabled || (this.props.parentControl !== null && !this.props.parentControl.flags.isEditEnabled)) {

            return
        }

        document.addEventListener('click', this.onClick)

        const isFlagChanged = this.setFlags(true)
        if (isFlagChanged) {
            this.props.updateControlState(this.props.control, this.props.rootControl)
        }
    }

    cancelEditHandler = () => {
        document.removeEventListener('click', this.onClick)

        this.props.control.flags.update(false)

        const isFlagChanged = this.setFlags(false)
        if (isFlagChanged) {
            this.props.updateControlState(this.props.control, this.props.rootControl)
        }
    }

    applyChangesHandler = data => {

        this.props.control.update(data)
        this.props.parentControl.flags.isChanged = true
        this.props.rootControl.flags.isChanged = true
        // this.props.onApply(this.props.control.id, data)
        this.cancelEditHandler()

        //this.props.updateControlState(this.props.control, this.props.rootControl)
    }

    cancelChangesHandler = () => {
        this.cancelEditHandler()
    }

    deleteHandler = () => {

        this.props.deleteControl(this.props.control, this.props.rootControl)
        this.cancelEditHandler()
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClick)
    }

    render() {

        let text = 'No data found'

        if (!!this.props.globals && !!this.props.global) {
            const data = this.props.globals[this.props.global]
            if (!!!data) {
                return null
            }

            text = getByData(data, this.props.match.params.language, this.props.isNoWrappers)
            return !!text ? text : null
        }

        if (this.props.text !== undefined) {
            text = this.props.text
        }

        if (this.props.topic !== undefined) {
            text = getByTopic(this.props.topic, this.props.match.params.language)
        }

        if (this.props.data !== undefined && this.props.data !== null) {
            text = getByData(this.props.data, this.props.match.params.language, this.props.isNoWrappers)

            if (this.props.data.bold) {
                text = <span className={classes.Bold}>{text}</span>
            }
        }

        let component = <span ref={this.wrapperRef}>{text}</span>

        if (this.props.parentControl !== null && this.props.parentControl.flags.isEditEnabled && !!!this.props.noEdit) {
            component = <span className={classes.EditAllowed} onClick={() => this.startEditHandler()} ref={this.wrapperRef}>{text}</span>
        }

        let editControl = null
        if (!!!this.props.noEdit) {
            editControl = <MultiEdit
                data={this.props.control}
                onCancel={() => this.cancelChangesHandler()}
            />
        }

        if (this.props.control !== null && this.props.isEditGlobal && this.props.control.flags.isEditEnabled) {
            component = (
                <span ref={this.wrapperRef}>
                    <span className={classes.EditEnabled}>{text}</span>
                    {editControl}
                </span>)
        }

        return component
    }
}

function mapStateToProps(state, initialProps) {
    const controlId = initialProps.data !== undefined ? initialProps.data.id : initialProps.id === undefined ? null : initialProps.id

    const controlFromState = getControlFromState(state.controls, initialProps.dataKey, controlId, 'local')

    return {
        isEditGlobal: state.edit.isEditModeActive,
        control: controlFromState.control === undefined ? null : controlFromState.control,
        parentControl: controlFromState.control !== null && controlFromState.control.parent !== undefined ? controlFromState.control.parent : null,
        rootControl: controlFromState.rootControl,
        globals: state.globals.controls
    }
}

function mapDispatchToProps(dispatch) {
    return {
        switchEditMode: isActive => dispatch(switchEditMode(isActive)),
        save: (dataKey, data) => dispatch(save(dataKey, data)),
        updateControlState: (control, rootControl) => dispatch(updateControlState(control, rootControl)),
        deleteControl: (control, rootControl) => dispatch(deleteControl(control, rootControl))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Text))