import React, { Component } from 'react'
import { connect } from 'react-redux'
import classes from './About.css'
import Button from '../../components/UI/Button/Button'
import Text from '../../components/UI/Text/Text'
import DivEx from '../../components/Controls/DivEx/DivEx'
import { createServiceControl } from '../../redux/actions/controlsEx'
import WurmScreenshot6 from '../../resources/images/wurm-6.JPG'
import uuidv4 from 'uuid/v4'

const aboutList1Id = uuidv4()
const aboutList2Id = uuidv4()

class About extends Component {

    render() {

        const controlName = 'news-list'
        const serviceButton = (
            <Button
                type="success"
                onClick={() => this.props.createServiceControl(controlName)}>
                Recreate {controlName}
            </Button>
        )

        return (
            <React.Fragment>
                <div className={classes.About}>

                    <img src={WurmScreenshot6} alt="Wurm Unlimited" title="Wurm Unlimited" />

                    <h2 className={classes.ServerName}>[RU|EN] Silver Blades</h2>

                    <DivEx id={aboutList1Id} dataKey="about-list-1" />
                    <DivEx id={aboutList2Id} dataKey="about-list-2" />

                    <div className={classes.iframeContainer}>
                        <h3><Text global="join-discord" /></h3>
                        <br />
                        <iframe src="https://discordapp.com/widget?id=472405145112674304&theme=light" className={classes.iframe} frameBorder="0" title="discordIFrame"></iframe>
                    </div>
                </div>

                <br />

                <div className={classes.ContactContainer}>
                </div>
            </React.Fragment>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createServiceControl: controlName => dispatch(createServiceControl(controlName))
    }
}

export default connect(null, mapDispatchToProps)(About)
