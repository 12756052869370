import { deepCopyEx2 } from '../redux/actions/general'

class ControlState {

    constructor(data) {

        this.update(data)
    }

    type = null
    layout = null
    style = null
    title = null
    data = null
    dataKey = null
    dataTemplate = null
    dataGrow = null

    update = data => {

        Object.keys(this).every(property => {

            if (typeof this[property] === 'function') {
                return true
            }

            if (data[property] !== undefined) {
                this[property] = deepCopyEx2(data[property])
            }

            return true
        })
    }
}

export default ControlState