import React, { Component } from 'react'
import classes from './LanguageSelector.css'
import { Link, withRouter } from 'react-router-dom'
import gb from '../../../resources/flags/gb.svg'
import ru from '../../../resources/flags/ru.svg'

class LanguageSelector extends Component {

    render() {

        const urlParts = this.props.history.location.pathname.split('/')
        const newUrlParts = urlParts.slice(2, urlParts.length)
        const rawUrl = newUrlParts.join('/') //newUrlParts.join('/').slice(0, -1)
        const url = urlParts.length === 2 ? rawUrl : '/' + rawUrl

        const clsRU = this.props.match.params.language === 'ru' ? classes.active : null
        const clsEN = this.props.match.params.language === 'en' ? classes.active + ' ' + classes.last : classes.last
        
        return (
            <div className={classes.LanguageSelector}>
                <div className={classes.LanguageContainer}>
                    <div className={clsRU}><Link to={'/ru' + url}><img src={ru} alt="RU" /></Link></div>
                    <div className={clsEN}><Link to={'/en' + url}><img src={gb} alt="EN" /></Link></div>
                </div>
            </div>
            )
    }
}

export default withRouter(LanguageSelector)