import React, { Component } from 'react'
import classes from './Toggle.css'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { switchEditMode } from '../../../redux/actions/edit'
import ToggleButton from './ToggleButton/ToggleButton'

class Toggle extends Component {

    switchHandler = (e, isActive) => {

        if (this.props.isActive === isActive) {
            e.preventDefault()
            return;
        }

        this.props.switchEdit(isActive)
    }

    render() {
        const classOn = this.props.isActive ? classes.activeOn : classes.disabledOn;
        const classOff = !this.props.isActive ? classes.activeOff : classes.disabledOff;

        return (
            <div className={classes.Toggle}>
                <div className={classes.Title}>{this.props.title}</div>
                <div>
                    <ToggleButton onClick={e => this.switchHandler(e, true)} active={this.props.isActive} type="ON" position="left">ON</ToggleButton>
                    <ToggleButton onClick={e => this.switchHandler(e, false)} active={!this.props.isActive} type="OFF" position="right">OFF</ToggleButton>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        isActive: state.edit.isEditModeActive
    }
}

function mapDispatchToProps(dispatch) {
    return {
        switchEdit: isActive => dispatch(switchEditMode(isActive))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toggle)