import { BaseControl, ControlFlags, ControlState } from './index'
import { deepCopyEx, getRoot, deepFindEx } from '../redux/actions/general'

const nonServerStateProperties = ['id', 'parent']

const getParentForLocalState = control => {

    const rootControl = getRoot(control)
    const propertiesToIgnore = ['parent', 'serverStateRaw', 'serverState']

    const result = deepFindEx(rootControl, 'id', control.id, propertiesToIgnore)

    return result
}

const createObjectFromRef = (control, resultArray) => {
    const resultFinal = control.getRaw(resultArray)
    return resultFinal
}

class ComplexControl extends BaseControl {

    constructor(data, controlId = null, parent, serverId, parentKey) {
        super(data, controlId, parent, parentKey)

        if (data instanceof BaseControl) {
            // creating copy of another control

            this.copy(data)
        } else {
            // creating new control from server data

            this.update(data)
            this.dataKey = data.dataKey
            this.serverId = serverId
        }

        if (this.dataKey === 'about-list-1') {
            // debugger
        }
    }

    dataKey = null
    localState = null
    serverState = null
    serverStateRaw = null
    serverId = null

    update = data => {
        this.serverStateRaw = deepCopyEx(data)
        this.createServerState()

        if (!this.flags.isEditEnabled) {
            this.copyServerToLocal()
        }
    }

    updateFlags = data => {
        this.flags = new ControlFlags(data)
    }

    createServerState = () => {

        if (this.serverState === null) {
            this.serverState = new ControlState(this.serverStateRaw)
        }
    }

    copyServerToLocal = () => {
        //const parent = getParentForLocalState(this.serverState)
        this.localState = new ControlState(this.serverState)
    }

    getRaw = () => {

    }
}

export default ComplexControl