import { BaseControl } from './index'
import { parseTagsEx } from '../redux/actions/general'

const createRef = control => {
    return { ref: control.dataKey }
}

class BasicControl extends BaseControl {

    constructor(data, controlId, parent, parentKey) {
        super(data, controlId, parent, parentKey)

        if (data instanceof BaseControl) {
            // creating copy of another control

            this.update(data)

            //this.copy(data)
        } else {
            // creating new control from server data

            this.update(data)
        }
    }

    update = data => {

        // copy properties and parseTags

        Object.keys(data).every(property => {

            if (data[property] === undefined || data[property] === null) {
                return true
            }

            if (property === 'id') {
                return true
            }

            const parseResult = parseTagsEx(data[property])
            this[property] = data[property]
            if (!(parseResult === data[property])) {
                this[`${property}-parsed-tags`] = parseResult
            }
            return true
        })
    }

    getRaw = templateParam => {

    }
}

export default BasicControl