import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import classes from './ToggleButton.css'

const ToggleButton = props => {

    const cls = [classes.ToggleButton]

    if (props.type !== null) {
        cls.push(classes[props.type])
    }
    else {
        cls.push(classes.ON)
    }

    if (props.active) {
        cls.push(classes.active)
    }

    if (props.position !== null) {
        cls.push(classes[props.position])
    }

    return (
        <NavLink to="#" onClick={props.onClick} className={cls.join(' ')}>{props.children}</NavLink>
        )
}

export default ToggleButton