import {combineReducers} from 'redux'
import authReducer from './auth'
import editReducer from './edit'
import generalReducer from './general'
import controlsReducer from './controls'
import referenceReducer from './references'
import globalsReducer from './globals'

export default combineReducers({
    auth: authReducer,
    edit: editReducer,
    general: generalReducer,
    controls: controlsReducer,
    references: referenceReducer,
    globals: globalsReducer
})