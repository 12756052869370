import React, { Component } from 'react'
import { connect } from 'react-redux';
import classes from './Loading.css'
import Text from '../UI/Text/Text'
import logo from '../../resources/images/Wurm_unlimited_Logo.png'
import { switchLoading, setLoadingComplete } from '../../redux/actions/globals'

let renderCounter = 0
const renderCounterForCompleteLoading = 2

class Loading extends Component {

    render() {

        if (this.props.isLoadingComplete) {
            return <img src={logo} alt="Wurm Unlimited" className={classes.CompleteLoadingLogo} title="Wurm Unlimited" /> 
        }

        let clsBackdrop = [classes.Loading]
        let clsLogo = [classes.LoadingLogo]
        let clsProgressText = [classes.LoadingText]
        let clsProgress = [classes.ldsEllipsis]

        if (!this.props.isLoading) {
            clsBackdrop = [classes.Loaded]
            clsLogo = [classes.LoadedLogo]
            clsProgressText = [classes.LoadingTextHidden]
            clsProgress = [classes.ldsEllipsisHidden]
            renderCounter++
        }

        if (renderCounter >= renderCounterForCompleteLoading) {
            this.props.setLoadingComplete()
        }

        const control =
            <React.Fragment>
                <div className={clsBackdrop.join(' ')}></div>
                <img src={logo} alt="Wurm Unlimited" className={clsLogo.join(' ')} />
                <div className={clsProgressText}>Loading...</div>
                <div className={clsProgress}><div></div><div></div><div></div><div></div></div>
            </React.Fragment>

        return control
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.globals.flags.isLoading,
        isLoadingComplete: state.globals.flags.isLoadingComplete
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setLoadingComplete: () => dispatch(setLoadingComplete()),
        switchLoading: () => dispatch(switchLoading())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading)