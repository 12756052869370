import { SWITCH_EDIT_MODE } from '../actions/actionTypes';
import { deepCopyEx, getBool } from '../actions/general'

const initialState = {
    isEditModeActive: getBool(localStorage.getItem('isEditModeActive'))
}

export default function editReducer(state = initialState, action) {

    switch (action.type) {
        case SWITCH_EDIT_MODE:
            return {
                ...state,
                isEditModeActive: action.payload
            }
        default:
            return state
    }
}