import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import classes from './NavLinkEx.css'
import { getLink } from '../../../redux/actions/general'

const handleClick = (e, props) => {
    if (props.link.to === null) {
        e.preventDefault()
    }
}

const NavLinkEx = props => {

    const url = getLink(props.link.to)

    const navlink = props.link.to !== null ?
        <NavLink
            to={url}
            exact={props.link.exact}
            activeClassName={classes.active}
            onClick={e => handleClick(e, props)} 
        >
            {props.link.label}
        </NavLink>
        :
        <NavLink
            to={'#'}
            exact={props.link.exact}
            activeClassName={classes.active}
            onClick={e => handleClick(e, props)} 
        >
            {props.link.label}
        </NavLink>

    return navlink
}

export default withRouter(NavLinkEx)