import { ControlFlags } from './index'
import uuidv4 from 'uuid/v4'

let uniqueIdCounter = 0
const propertiesToIngnore = ['id', 'type', 'parent', 'uniqueId']

class BaseControl {

    id = null
    type = null
    parent = null
    parentKey = null
    dataGrow = null
    flags = new ControlFlags()
    uniqueId = 0

    constructor(data, controlId = null, parent) {
        if (data.type === undefined || data.type === null) {
            throw new Error('\'type\' property required')
        }

        this.id = controlId === null ? uuidv4() : controlId
        this.type = data.type
        this.parent = parent
        this.uniqueId = ++uniqueIdCounter
        this.dataGrow = data.dataGrow
    }

    copy = data => {
        // Shallow copy properties

        Object.keys(data).every(property => {

            if ((typeof this[property] === 'function') || (data[property] === undefined)) {
                return true
            }

            if (propertiesToIngnore.includes(property)) {
                return true
            }
            this[property] = data[property]
            return true
        })
    }

    delete = () => {

    }
}

export default BaseControl