import React, { Component } from 'react'
import classes from './Menu.css'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import logo from '../../../resources/images/Wurm_unlimited_Logo.png'
import NavLinkEx from '../../UI/NavLinkEx/NavLinkEx'
import Text from '../../UI/Text/Text'
import { connect } from 'react-redux'

class Menu extends Component {

    createMenu = data => {

        return (
            <div className={classes.MenuInner}>
                <ul className={classes.Primary}>
                    {
                        data.map((menuItem, index) => {
                            return menuItem['submenu'] !== undefined ?
                                this.createSubMenu(menuItem, index) 
                                :
                                this.createMenuItem(menuItem, index)
                        })
                    }
                </ul>
            </div>
            )
    }


    createMenuItem = (dataItem, dataItemIndex) => {
        let isVisible = true;

        if (dataItem.authentication === 'none' && this.props.isAuthenticated) {
            isVisible = false
        }

        if (dataItem.authentication === 'admin' && !this.props.isAuthenticated) {
            isVisible = this.props.isDemo
        }

        if (!isVisible) {
            return null
        }

        const isNotClickable = (dataItem.to === null)
        return (
            isNotClickable ?
                <li key={dataItemIndex} className={classes.NotClickable}>
                    <NavLinkEx link={dataItem} />
                </li>
                :
                <li key={dataItemIndex}>
                    <NavLinkEx link={dataItem} />
                </li>
        )
    }

    createSubMenu = (dataItem, dataItemIndex) => {

        const isNotClickable = (dataItem.to === null)

        return (
            isNotClickable ?
                <li key={dataItemIndex} className={classes.NotClickable}>
                    <NavLinkEx link={dataItem} />
                    <ul className={classes.SubMenu}>
                        {
                            dataItem.submenu.map((subMenuDataItem, index) => {
                                return this.createMenuItem(subMenuDataItem, index)
                            })
                        }
                    </ul>
                </li>
                :
                <li key={dataItemIndex}>
                    <NavLinkEx link={dataItem} />
                    <ul className={classes.SubMenu}>
                        {
                            dataItem.submenu.map((subMenuDataItem, index) => {
                                return this.createMenuItem(subMenuDataItem, index)
                            })
                        }
                    </ul>
                </li>
            )
    }

    render() {
        const menuData =
            [
                { to: '/', label: <Text global="about" />, exact: true, authentication: 'any' },
                { to: '/details', label: <Text global="details" />, exact: false, authentication: 'any' },
                { to: '/news', label: <Text global="news" />, exact: false, authentication: 'any' },
                { to: '/rules', label: <Text global="rules" />, exact: false, authentication: 'any' },
                {
                    to: null, label: <Text global="maps" />, exact: false, authentication: 'any',
                    submenu:
                        [
                            { to: '/maps/center', label: <Text global="x2-center-pve" />, exact: false, authentication: 'any' },
                            { to: '/maps/north', label: <Text global="x2-north-pvp" />, exact: false, authentication: 'any' }
                        ]
                },
                { to: '/admin', label: <Text global="admin" />, exact: false, authentication: 'admin' }
            ]

        return (
                <div className={classes.Menu}>
                    <div alt="Wurm Unlimited" className={classes.Logo} />
                    {this.createMenu(menuData)}
                    <LanguageSelector />
                </div>
            )
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.auth.token,
        isDemo: !!state.globals.flags.isDemo,
        isLoading: state.globals.flags.isLoading
    }
}

export default connect(mapStateToProps)(Menu)