import React, { Component } from 'react'
import classes from './Admin.css'
import { connect } from 'react-redux'
import { logout } from '../../redux/actions/auth'
import { withRouter } from 'react-router-dom'
import Button from '../../components/UI/Button/Button'
import Toggle from '../../components/UI/Toggle/Toggle'
import Text from '../../components/UI/Text/Text'
import RedirectEx from '../../components/Router/RedirectEx'
import Demo from './Demo/Demo'

class Admin extends Component {

    render() {

        const buttons = this.props.isDemo ? 
            <Demo />
            :
            <Button
                type="warning"
                onClick={() => { this.props.logout() }}
            >
                <Text global="logout" />
            </Button>

        const editModeText = <Text global="edit-mode" />

        return (
            this.props.isAuthenticated || this.props.isDemo ?
                <div className={classes.Admin}>
                    <div onSubmit={this.submitHandler} className={classes.AdminInner}>
                        <div className={classes.Title}><Text global="administration-panel" /></div>

                        <Toggle title={editModeText} />

                        <hr />

                        <div className={classes.Buttons}>
                            {buttons}
                        </div>

                    </div>
                </div>
                :
                <RedirectEx to='/login' relative={true} />
        )
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.auth.token,
        isDemo: !!state.globals.flags.isDemo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logout())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin))