import { REDIRECT, RESET_REDIRECT } from '../actions/actionTypes'

const initialState = {
    redirectTo: null
}

export default function generalReducer(state = initialState, action) {

    switch (action.type) {
        case REDIRECT:
            return {
                ...state,
                redirectTo: action.payload
            }
        case RESET_REDIRECT:
            return {
                ...state,
                redirectTo: null
            }
        default:
            return state
    }
}