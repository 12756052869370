import React, { Component } from 'react'
import classes from './Map.css'
import { withRouter } from 'react-router-dom'

class Map extends Component {

    render() {

        let url = 'https://silverblades-wu.com/maps/center/index.html'
        if (this.props.match.params.maptype === 'north') {
            url = 'https://silverblades-wu.com/maps/north/index.html'
        }

        return (
            <div className={classes.Map}>
                <iframe src={url} />
            </div>
        )
    }
}

export default withRouter(Map)