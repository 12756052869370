import { getFirebase } from '../../data/firebase'
import { UPDATE_GLOBALS, LOAD_GLOBALS, SET_LANGUAGE } from './actionTypes'

export function loadGlobals() {

    return dispatch => {

        getFirebase().database().ref('globals').on('value', (childSnapshot, prevChildKey) => {

            const result = childSnapshot.val()

            if (result === null) {
                return
            }

            result.flags.isLoading = false

            dispatch(loadGlobalsInner(result))
        })
    }
}

export function setLanguage(language) {

    return {
        type: SET_LANGUAGE,
        payload: language
    }
}
export function switchLoading() {

    return (dispatch, getState) => {

        const state = getState().globals

        state.flags.isLoading = !state.flags.isLoading
        dispatch(updateGlobals(state))
    }
}

export function setLoadingComplete() {

    return (dispatch, getState) => {
        const state = getState().globals

        state.flags.isLoadingComplete = true
        dispatch(updateGlobals(state))
    }
}

function loadGlobalsInner(data) {

    return {
        type: LOAD_GLOBALS,
        payload: data
    }
}

function updateGlobals(data) {

    return {
        type: UPDATE_GLOBALS,
        payload: data
    }
}