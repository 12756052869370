import ControlFactory from '../../data/ControlFactory'

import {
    CONTROLS_UPDATE,
    CONTROLS_REMOVE_ALL
} from '../actions/actionTypes';

const initialState = {
}

export default function controlsReducer(state = initialState, action) {

    switch (action.type) {
        case CONTROLS_UPDATE:
            return {
                ...state,
                [action.payload.id]: ControlFactory.copy(action.payload),
            }
        case CONTROLS_REMOVE_ALL:
            return {
                ...initialState
            }
        default:
            return state
    }
}