import React from 'react'
import { NavLink } from 'react-router-dom'
import classes from './Button.css'

const Button = props => {
    const cls = [
        classes.Button,
        classes[props.type]
    ]

    if (props.disabled) {
        cls.push(classes.ButtonDisabled)
    }

    return (
        <NavLink
            to="#"
            onClick={props.disabled ? e => { e.preventDefault() } : props.onClick}
            className={cls.join(' ')}>
            {props.children}
        </NavLink>
    )
}

export default Button