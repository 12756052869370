import { SET_REFERENCE_TREE, SAVE_REFERENCE } from './actionTypes'
import { getData, setData, deleteData } from '../../data/references'
import $ from 'jquery'

export function save(dataKey, data) {

    return (dispatch, getState) => {

        let payload = { ...getState().references.controls }

        payload[dataKey] = data

        dispatch(innerSave(payload))
    }
}

export function innerSave(payload) {
    return {
        type: SAVE_REFERENCE,
        payload
    }
}

export function setRef(refIndex, reference, isRootElement) {
    return (dispatch) => {

        if (refIndex === null || refIndex === undefined || reference.current === undefined || reference.current === null) {
            return
        }

        const isRoot = isRootElement === undefined ? false : isRootElement

        const newElement = { currentRef: reference.current, parentElement: null, childrenElements: [] }
        let tree = getData()[refIndex]

        if (tree === undefined) {
            tree = { rootElement: null, buffer: [] }
        }

        if (!isRoot) {
            tree.buffer.push(newElement)
        } else {
            finalize(tree, newElement)
        }

        setData(refIndex, tree)
    }
}

export function clearRefs(refIndex, isRootElement) {
    if (isRootElement) {
        deleteData(refIndex)
    }
}

const not = bufferRef => {
    return ref => {
            return bufferRef !== ref
        }
}

function finalize(tree, rootElement) {

    tree.rootElement = rootElement

    const refBuffer = tree.buffer.map(bufferElement => {
        return $(bufferElement.currentRef)
    })

    tree.buffer.forEach(element => {
        if (element.parentElement !== null) {
            return true
        }

        const filteredRefBuffer = refBuffer.filter(not(element.currentRef))
        const closestParentRef = $(element.currentRef).closest(filteredRefBuffer)
        let parentElement = findElementByRef(tree, closestParentRef)
        if (parentElement === null) {
            parentElement = rootElement
        }

        element.parentElement = parentElement
        parentElement.childrenElements.push(element)
    })

    tree.buffer = []
}

function findElementByRef(tree, ref) {
    let result = null

    tree.buffer.forEach(bufferElement => {
        if (bufferElement.currentRef === ref) {
            result = bufferElement
            return false
        }
    })

    return result
}

