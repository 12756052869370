import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { getLink, resetRedirect } from '../../redux/actions/general'

class RedirectEx extends Component {

    componentDidMount() {
        this.props.resetRedirect()
    }

    render() {
        const url = !this.props.relative ? this.props.to : getLink(this.props.to)

        return (
            <Redirect to={url} />
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        resetRedirect: () => dispatch(resetRedirect())
    }
}

export default withRouter(connect(null, mapDispatchToProps)(RedirectEx))