import React, { Component } from 'react'
import { connect } from 'react-redux'
import classes from './DivEx.css'
import ReactJson from 'react-json-view'
import TopEditPanel from '../../UI/TopEditPanel/TopEditPanel'
import Text from '../../UI/Text/Text'
import Separator from '../../UI/Separator/Separator'
import AddNewItem from '../../UI/AddNewItem/AddNewItem'
import MultiEdit from '../../UI/Edit/MultiEdit/MultiEdit'
import { loadList, getControlFromState, updateControlState } from '../../../redux/actions/controlsEx'
import { isClickInControl, getRoot } from '../../../redux/actions/general'
import { create } from 'istanbul-reports';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';

class DivExInner extends Component {

    constructor(props) {
        super(props)
        this.wrapperRef = React.createRef()
        this.editRef = React.createRef()
    }

    localStorage = {
        isRoot: true,
        edit: {
            onStart: null,
            classes: null
        }
    }

    initializeLocalStorage() {
        const controlState = this.getState()
        let editClasses = null

        this.localStorage.isRoot = (this.props.rootControl.id === this.props.control.id)

        if (this.props.isEditGlobal && this.localStorage.isRoot) {
            editClasses = this.props.control.flags.isEditEnabled ? [classes.RootEditEnabled] : [classes.RootEditAllowed]
        } else if (this.props.isEditGlobal && this.props.parentControl !== null && this.props.parentControl.flags.isEditEnabled) {
            if (this.props.parentControl.flags.isChildEditEnabled) {
                editClasses = this.props.control.flags.isEditEnabled ? [classes.EditEnabled] : null
            } else {
                editClasses = this.props.control.flags.isEditEnabled ? [classes.EditEnabled] : [classes.EditAllowed]
            }
        }

        this.localStorage.edit = {
            onStart: this.localStorage.edit.onStart !== null ? this.localStorage.edit.onStart : (e) => this.startEditHandler(e),
            classes: editClasses
        }

        /*
        if (this.props.control.flags !== undefined && this.props.control.flags.isEditEnabled) {
            this.localStorage.edit.classes = [classes.EditEnabled]
        }
        */
        this.localStorage.revision = this.props.revision === undefined ? 0 : this.props.revision + 1

        if (this.localStorage.revision > 10) {
            debugger
        }
    }

    setFlags(isEditEnabled) {

        let isFlagChanged = false
        const isChildEditEnabled = isEditEnabled

        if (this.props.control.flags.isEditEnabled !== isEditEnabled) {
            this.props.control.flags.isEditEnabled = isEditEnabled
            isFlagChanged = true
        }

        if (this.props.parentControl !== null) {
            if (isChildEditEnabled) {
                this.props.parentControl.flags.isChildEditEnabled = true
                this.props.parentControl.flags.childInEditId = this.props.control.id
            } else {
                if (this.props.parentControl.flags.childInEditId === this.props.control.id) {
                    this.props.parentControl.flags.isChildEditEnabled = false
                    this.props.parentControl.flags.childInEditId = null
                }
            }
        }

        return isFlagChanged
    }

    startEditHandler(e) {

        if (!this.props.isEditGlobal || this.props.control === null || this.props.control.flags === undefined || this.props.control.flags.isEditEnabled || (this.props.parentControl !== null && !this.props.parentControl.flags.isEditEnabled)) {

            return
        }

        if (!!this.editRef.current) {
            //const isClickInEditControl = isClickInControl(e, this.editRef.current)
            const isClickInEditControl = this.editRef.current.contains(e.target)
            if (isClickInEditControl) {
                return
            }
        }

        document.addEventListener('click', this.onClick)

        console.log('startEditHandler')

        const isFlagChanged = this.setFlags(true)
        if (isFlagChanged) {
            this.props.updateControlState(this.props.control, this.props.rootControl)
        }
    }

    cancelEditHandler = () => {

        document.removeEventListener('click', this.onClick)

        // this.props.control.flags.update(false)

        console.log('cancelEditHandler')

        const isFlagChanged = this.setFlags(false)
        if (isFlagChanged) {
            this.props.updateControlState(this.props.control, this.props.rootControl)
        }
    }

    deleteLocalHandler = dataIndex => {
        const indexParts = dataIndex.split('.')
        const index = Number(indexParts[1])
        const control = this.props.control

        control.localState.data.splice(index, 1)

        this.props.updateList(control)
    }

    onClick = e => {
        if (this.wrapperRef.current === null) {
            this.cancelEditHandler()
            return
        }

        const isClickInEditControl = isClickInControl(e, this.editRef.current) 
        if (isClickInEditControl) {
            e.preventDefault()
            return
        }

        //const isCancelRequired = !this.wrapperRef.current.contains(e.target)
        const isCancelRequired = !isClickInControl(e, this.wrapperRef.current) //!this.wrapperRef.current.contains(e.target)

        if (isCancelRequired) {
            this.cancelEditHandler()
        }
    }

    getState() {

        if (this.props.control === null) {
            return null
        }

        let result = null

        if (this.props.control.localState !== undefined) {
            result = this.props.control.localState
        } else if (this.props.control !== undefined) {
            result = this.props.control
        }

        return result
    }

    createControlContextDiv() {

        const state = this.getState()

        if (this.props.control.flags.isLoading) {
            return null
        }

        let control = null
        let addListItemControl = null;

        if (this.props.control.flags.isEditEnabled) {
            addListItemControl = (
                <AddNewItem data={this.props.control} />
            )
        }

        if (state === undefined) {
            control = <Text data={state} />
        } else if (state.data !== undefined && state.data !== null) {

            control = state.data.map((item, index) => {

                let itemControl = null

                if (item.localState === null || item.localState === undefined || item.localState.type === undefined) {
                    itemControl = <Text data={item} />
                } else {
                    itemControl = <DivEx data={item} revision={this.localStorage.revision} />
                }

                return (
                    <React.Fragment key={index}>
                        {itemControl}
                    </React.Fragment>
                )
            })

            if (state.title !== null) {
                control = (
                    <React.Fragment>
                        <h3><Text data={state.title} /></h3>
                        <Separator />
                        {this.props.control.dataGrow === 'top' ? addListItemControl : null}
                        {control}
                        {!!!this.props.control.dataGrow || this.props.control.dataGrow === 'bottom' ? addListItemControl : null}
                    </React.Fragment>
                )
            } else {
                control = (
                    <React.Fragment>
                        {control}
                        {addListItemControl}
                    </React.Fragment>
                )
            }

        }

        return control
    }

    createControlContextList() {
        const state = this.getState()

        if (state === undefined || this.props.control === undefined) {
            return null
        }

        let control = null
        let addListItemControl = null;

        if (this.props.control.flags.isEditEnabled) {
            addListItemControl = (
                <li>
                    <AddNewItem data={this.props.control} />
                </li>
                )
        }

        if (state.type === 'text') {
            control = <Text data={state.data} />
        } else if (state.data !== undefined && state.data !== null) {

            control = state.data.map((item, index) => {

                let itemControl = null

                if (item.type === 'text') {
                    itemControl = <Text data={item} />
                } else if (state.data !== undefined) {
                    itemControl = <DivEx data={item} revision={this.localStorage.revision} />
                }

                return (
                    <li key={index}>
                        {itemControl}
                    </li>
                )
            })

            if (state.title !== null) {
                control = (
                    <React.Fragment>
                        <h3><Text data={state.title} /></h3>
                        <Separator />
                        <ul className={classes.List}>
                            {this.props.control.dataGrow === 'top' ? addListItemControl : null}
                            {control}
                            {!!!this.props.control.dataGrow || this.props.control.dataGrow === 'bottom' ? addListItemControl : null}
                        </ul>
                    </React.Fragment>
                )
            } else {
                control = (
                    <ul className={classes.List}>
                        {this.props.control.dataGrow === 'top' ? addListItemControl : null}
                        {control}
                        {!!!this.props.control.dataGrow || this.props.control.dataGrow === 'bottom' ? addListItemControl : null}
                    </ul>
                )
            }
        }

        return control
    }

    createControlContextTextArray() {
        const state = this.getState()

        if (state === undefined) {
            return null
        }

        let control = state.data.map((item, index) => {

            if (item.type !== 'text') {
                return null
            }

            const itemControl = <Text data={item} noEdit={true} />
            let separator = <br />

            if (item.separator !== undefined) {

                if (item.separator.indexOf('\n') !== -1) {
                    separator = <br />
                } else {
                    separator = item.separator
                }
            }
                
            return (
                <React.Fragment key={index}>
                    {itemControl}
                    {separator}
                </React.Fragment>
            )
        })

        return control
    }

    createControl = () => {

        const state = this.getState()
        const localState = state.localState !== undefined ? state.localState : state

        if (state === undefined) {
            return null
        }

        let control = null

        if (state.flags !== undefined && state.flags.isLoading) {
            control = <Text global="loading" />
            return control
        }

        const cls = [classes.DivEx]
        const clsMultiText = []

        if (this.props.control !== undefined && !this.localStorage.isRoot) {
            cls.push(classes.NoMargin)
            clsMultiText.push(classes.NoMargin)
        } else if (this.props.control !== undefined && this.localStorage.isRoot) {
            cls.push(classes.RootMargin)
            clsMultiText.push(classes.RootMargin)
        }

        if (this.localStorage.edit.onStart !== null) {
            cls.push(this.localStorage.edit.classes)
            clsMultiText.push(this.localStorage.edit.classes)
        }

        let topEditPanel = null
        if (this.localStorage.isRoot) {
            topEditPanel = <TopEditPanel data={this.props.control} />
        }

        switch (localState.type) {
            case 'div':
                control = (
                    <div className={cls.join(' ')} onClick={this.localStorage.edit.onStart} ref={this.wrapperRef}>
                        {topEditPanel}
                        {this.createControlContextDiv()}
                    </div>
                )
                break;
            case 'list':
                control = (
                    <div className={cls.join(' ')} onClick={this.localStorage.edit.onStart} ref={this.wrapperRef}>
                        {topEditPanel}
                        {this.createControlContextList()}
                    </div>
                )

                break;
            case 'text-array':
                const editControl = <div ref={this.editRef}><MultiEdit data={this.props.control} onCancel={() => this.cancelEditHandler()} /></div>
                //console.log('DivEx createControl', this.props.control.flags.isEditEnabled, this.props.control)
                control = (
                    <React.Fragment>
                        <div className={clsMultiText.join(' ')} onClick={e => this.localStorage.edit.onStart(e)} ref={this.wrapperRef}>
                            {this.createControlContextTextArray()}
                            {editControl}
                        </div>
                    </React.Fragment>
                )
                break;

            default:
                control = this.createControlContextDiv()
        }

        return control
    }

    componentDidMount() {
        if (this.props.id !== undefined) {
            this.props.loadList(this.props.dataKey, this.props.id)

        }
    }

    render() {

        if (this.props.control === null) {
            return null
        }

        if (this.props.control.dataKey === 'about-list-1-data-1') {
            // debugger
        }

        this.initializeLocalStorage()

        const controlState = this.getState()
        let control = null

        if (controlState !== null && controlState !== undefined) {
            control = this.createControl()
        }

        /*
        let control = <ReactJson src={this.props.control} />
        */
        return control
    }
}

function mapStateToProps(state, initialProps) {

    const controlId = initialProps.data !== undefined ? initialProps.data.id : initialProps.id === undefined ? null : initialProps.id

    if (initialProps.data && initialProps.data.dataKey === 'about-list-1-data-1') {
        // debugger
    }

    const controlFromState = getControlFromState(state.controls, initialProps.dataKey, controlId, 'local')

    return {
        isEditGlobal: state.edit.isEditModeActive,
        control: controlFromState.control === undefined ? null : controlFromState.control,
        parentControl: controlFromState.control !== null && controlFromState.control.parent !== undefined ? controlFromState.control.parent : null,
        rootControl: controlFromState.rootControl
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadList: (dataKey, id, rootControlId, controlPath) => dispatch(loadList(dataKey, id, rootControlId, controlPath)),
        updateControlState: (control, rootControl) => dispatch(updateControlState(control, rootControl))
    }
}

const DivEx = connect(mapStateToProps, mapDispatchToProps)(
    DivExInner
)

export default DivEx
