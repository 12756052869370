import React, { Component } from 'react'
import classes from './Logout.css'

class Logout extends Component {

    render() {
        return (
            <div className={classes.Logout}>
            </div>
        )
    }
}

export default Logout