import React, { Component } from 'react'
import { connect } from 'react-redux'
import classes from './TopEditPanel.css'
import Text from '../../UI/Text/Text'
import { getControlFromState, cancelLocalChanges, updateControlState, saveControl, setFlags } from '../../../redux/actions/controlsEx'
import { getText, getTextEx } from '../../../redux/actions/general'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

class TopEditPanel extends Component {

    constructor(props) {
        super(props)
        this.wrapperRef = React.createRef()
    }

    applyChangesToServerHandler = () => {

        this.props.saveControl(this.props.rootControl, this.onSaveSuccess, this.onSaveError)
    }

    onSaveSuccess = values => {
        setFlags(this.props.rootControl, ['isChanged'], false)
    }

    onSaveError = error => {
    }

    render() {
        if (!this.props.isEditGlobal || this.props.rootControl === undefined || this.props.rootControl === null) {
            return null
        }

        let control = null

        if (!this.props.rootControl.flags.isEditEnabled && this.props.rootControl.flags.isChanged) {
            const titleText = this.props.modifiedTitleText
            control = (
                <div className={classes.Warning} title={titleText}>
                    <Text global="modified" />
                </div>
            )
        } else if (this.props.rootControl.flags.isEditEnabled) {

            let clsAccept = classes.TopEditPanelAccept
            let clsCancel = classes.TopEditPanelCancel
            let acceptTitle = ''
            let cancelTitle = ''
            let acceptOnClick = null
            let cancelOnClick = null

            if (this.props.rootControl.flags.isChanged) {
                clsAccept = classes.TopEditPanelAcceptActive
                clsCancel = classes.TopEditPanelCancelActive
                acceptTitle = this.props.getText('save-changes')
                cancelTitle = this.props.getText('cancel-changes')
                acceptOnClick = () => this.applyChangesToServerHandler()
                cancelOnClick = () => this.props.cancelLocalChanges(this.props.rootControl)
            }

            control = (
                <div className={classes.TopEditPanel}>
                    <FontAwesomeIcon icon={faCheck} className={clsAccept} fixedWidth size="1x" title={acceptTitle} onClick={acceptOnClick} />
                    <FontAwesomeIcon icon={faTimes} className={clsCancel} fixedWidth size="1x" title={cancelTitle} onClick={cancelOnClick} />
                </div>
            )
        }

        return control
    }
}

function mapStateToProps(state, initialProps) {

    const controlId = initialProps.data !== undefined ? initialProps.data.id : initialProps.id === undefined ? null : initialProps.id

    const controlFromState = getControlFromState(state.controls, initialProps.dataKey, controlId, 'local')

    return {
        isEditGlobal: state.edit.isEditModeActive,
        rootControl: controlFromState.rootControl,
        modifiedTitleText: getTextEx('modified-title', state)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateControl: control => dispatch(updateControlState(null, control)),
        cancelLocalChanges: control => dispatch(cancelLocalChanges(control)),
        saveControl: (control, onSuccess, onError) => dispatch(saveControl(control, onSuccess, onError)),
        getText: key => dispatch(getText(key))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopEditPanel)