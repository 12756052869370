import React, { Component } from 'react'
import classes from './News.css'
import NavLinkEx from '../../components/UI/NavLinkEx/NavLinkEx'
import Separator from '../../components/UI/Separator/Separator'
import Text from '../../components/UI/Text/Text'
import DivEx from '../../components/Controls/DivEx/DivEx'
import uuidv4 from 'uuid/v4'

const newsListId = uuidv4()

class News extends Component {

    render() {
        return (
            <div className={classes.News}>

                <DivEx id={newsListId} dataKey="news-list" />

            </div>
        )
    }
}

export default News