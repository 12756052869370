import React, { Component } from 'react'
import { connect } from 'react-redux'
import classes from './MultiEdit.css'
import Text from '../../../UI/Text/Text'
import Input from '../../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import BasicControl from '../../../../data/BasicControl'
import ComplexControl from '../../../../data/ComplexControl'
import ControlFactory from '../../../../data/ControlFactory'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { getControlFromState, updateControlState, deleteControl, addData, deepControlCopy, updateControl } from '../../../../redux/actions/controlsEx'
import { deepCopyEx } from '../../../../redux/actions/general'

class MultiEdit extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isValid: true
        }

        if (!!!this.props.control) {
            return
        }
    }

    controlsToValidate = {}
    currentEditControl = null
    data = null

    initialize() {
        if (!!!this.props.control || !this.props.control.flags.isEditEnabled) {
            return
        }

        let control = null
        let data = null

        if (this.props.isNew) {
            control = this.props.control instanceof ComplexControl ? this.props.control.localState.dataTemplate : this.props.control.dataTemplate
            this.currentEditControl = deepControlCopy(control)
            this.data = control.type === 'text' ? [this.currentEditControl] : this.currentEditControl.dataTemplate
        } else {
            this.currentEditControl = this.props.control
            this.data = this.currentEditControl instanceof ComplexControl ? this.props.control.localState.data : this.props.control.data
            this.data = this.currentEditControl.type === 'text' ? [this.currentEditControl] : this.data
        }

        this.data.every(item => {

            const ruKey = this.getKey('ru', item)
            const enKey = this.getKey('en', item)
            const textKey = this.getKey('text', item)

            this.state[ruKey] = item.ru
            this.state[enKey] = item.en
            this.state[textKey] = item.text

            return true
        })
    }

    getKey(propertyName, control = null) {
        const result = !!control ? `${control.id}-${propertyName}` : `${this.currentEditControl.id}-${propertyName}`
        return result
    }

    onMultiChangeHandler(e, control, propertyName) {
        const key = this.getKey(propertyName, control)

        this.setState({
            [key]: e.target.value
        })

        this.setState((prevState, props) => ({
            isValid: this.validate(prevState)
        }))
    }

    validate(prevState) {

        let isValid = true

        Object.keys(prevState).every(key => {
            if (prevState[key] === '') {
                isValid = false
                return false
            }

            return true
        })

        return isValid
    }

    addNewControl() {

        //debugger
        this.data.every(control => {

            const ruKey = this.getKey('ru', control)
            const enKey = this.getKey('en', control)
            const textKey = this.getKey('text', control)
            const controlData = { en: this.state[enKey], ru: this.state[ruKey], text: this.state[textKey] }
            updateControl(control, controlData)
            return true
        })

        const isPushRequired = !!!this.props.control.dataGrow || this.props.control.dataGrow === 'bottom' ? true : false

        if (this.currentEditControl.type === 'text') {
            this.props.addData(
                this.props.control,
                this.props.rootControl,
                this.currentEditControl,
                isPushRequired)

        } else {
            this.currentEditControl.data = this.data
            delete this.currentEditControl.dataTemplate

            this.props.addData(
                this.props.control,
                this.props.rootControl,
                this.currentEditControl,
                isPushRequired)
        }
    }

    updateControl() {
        this.data.every(control => {

            const ruKey = this.getKey('ru', control)
            const enKey = this.getKey('en', control)
            const textKey = this.getKey('text', control)
            const controlData = { en: this.state[enKey], ru: this.state[ruKey], text: this.state[textKey] }
            control.update(controlData)
            return true
        })

        this.props.rootControl.flags.isChanged = true
    }

    applyMultiHandler(e) {

        e.preventDefault()

        const data = this.props.control instanceof ComplexControl ? this.props.control.localState.data : this.props.control.data
        let control = null

        if (this.props.isNew) {
            this.addNewControl()
        } else {
            this.updateControl()
        }

        this.props.onCancel()
    }

    cancelMultiHandler() {
        this.props.onCancel()
    }

    deleteMultiHandler() {
        this.props.deleteControl(this.props.control, this.props.rootControl)
        this.props.onCancel()
    }

    getMultiTextControl() {

        if (!!!this.props.control) {
            return null
        }

        if (!this.props.control.flags.isEditEnabled) {
            return null
        }

        const cls = [
            classes.Edit
        ]

        const clsInner = [
            classes.EditInner,
            classes.box,
            classes.box4,
            classes.shadow4
        ]

        const allLanguagesText = <Text global="all-languages" />
        const russianText = <Text global="russian" />
        const englishText = <Text global="english" />

        const iconButtons = !(this.props.isNew || (!!this.props.control.parentKey && this.props.control.parentKey[this.props.control.parentKey.length - 1] === 'title')) ?
            <div className={classes.IconButtons}>
                <div className={classes.SlidingButton} onClick={() => this.deleteMultiHandler()}>
                    <div className={classes.SlidingButtonInner}>
                        <span><Text global="delete" /></span>
                    </div>
                    <FontAwesomeIcon icon={faTrashAlt} className={classes.Delete} fixedWidth size="1x" />
                </div>
            </div>
            :
            null

        const inputControls = this.data.map((item, i) => {

            const inputs = item.text === undefined || item.text === null ?
                <React.Fragment>
                    <Input value={this.state[this.getKey('ru', item)]} label={russianText} multiline={2} onChange={e => this.onMultiChangeHandler(e, item, 'ru')} />
                    <Input value={this.state[this.getKey('en', item)]} label={englishText} multiline={2} onChange={e => this.onMultiChangeHandler(e, item, 'en')} />
                </React.Fragment>
                :
                <Input value={this.state[this.getKey('text', item)]} label={allLanguagesText} multiline={2} onChange={e => this.onMultiChangeHandler(e, item, 'text')} />

            const inputControl =
                <React.Fragment key={i}>
                    {inputs}
                    <hr />
                </React.Fragment>


            return inputControl
        })

        const control =
            <div className={cls.join(' ')}>
                <div className={clsInner.join(' ')}>

                    {inputControls}

                    <div className={classes.Buttons}>
                        <div className={classes.MainButtons}>
                            <Button
                                type="success"
                                onClick={e => this.applyMultiHandler(e)}
                                disabled={!this.state.isValid}
                            >
                                <Text global="accept" />
                            </Button>

                            <Button
                                type="warning"
                                onClick={() => this.cancelMultiHandler()}
                            >
                                <Text global="cancel" />
                            </Button>
                        </div>

                        {iconButtons}
                    </div>
                </div>
            </div>

        return control
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.control.id !== this.props.control.id) {
            this.initialize()
        }
    }

    render() {

        if (!this.props.isEditGlobal || !!!this.props.control) {
            return null
        }

        if (!!!this.currentEditControl) {
            this.initialize()
        }

        const control = this.getMultiTextControl()

        return control
    }
}

function mapStateToProps(state, initialProps) {
    const controlId = initialProps.data !== undefined ? initialProps.data.id : initialProps.id === undefined ? null : initialProps.id
    const controlFromState = getControlFromState(state.controls, initialProps.dataKey, controlId, 'local')

    return {
        isEditGlobal: state.edit.isEditModeActive,
        control: controlFromState.control === undefined ? null : controlFromState.control,
        parentControl: controlFromState.control !== null && controlFromState.control.parent !== undefined ? controlFromState.control.parent : null,
        rootControl: controlFromState.rootControl
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateControlState: (control, rootControl) => dispatch(updateControlState(control, rootControl)),
        deleteControl: (control, rootControl) => dispatch(deleteControl(control, rootControl)),
        addData: (control, rootControl, data, isPush) => dispatch(addData(control, rootControl, data, isPush))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiEdit)
