import React, { Component } from 'react'
import classes from './Demo.css'
import { connect } from 'react-redux'
import Button from '../../../components/UI/Button/Button'
import Text from '../../../components/UI/Text/Text'
import { restoreContext, saveContext } from '../../../redux/actions/general'

class Demo extends Component {

    constructor(props) {
        super(props)
    }

    state = {
        isRestoringContext: false
    }

    restoreContextHandler() {
        this.props.restoreContext(() => this.onStart(), () => this.onFinish())
    }

    onStart() {
        this.setState({
            ...this.state,
            isRestoringContext: true
        })
    }

    onFinish() {
        this.setState({
            ...this.state,
            isRestoringContext: false
        })
    }

    render() {

        const serviceButton = (
            <Button
                type="success"
                onClick={this.props.saveContext}>
                
                    <Text global="save-context" />
            </Button>
        )

        return this.props.isDemo ?
            <div className={classes.Demo}>

                <Button
                    type="warning"
                    onClick={() => { this.restoreContextHandler() }}
                    disabled={this.state.isRestoringContext}
                >
                    <Text global="restore-context" />
                </Button>

                <div className={classes.DemoNotification}>
                    <Text global="restore-context-notice" />
                </div>
            </div>
            :
            null
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.auth.token,
        isDemo: !!state.globals.flags.isDemo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        restoreContext: (onStart, onFinish) => dispatch(restoreContext(onStart, onFinish)),
        saveContext: () => dispatch(saveContext())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Demo)