import React, { Component } from 'react'
import classes from './Rules.css'
import DivEx from '../../components/Controls/DivEx/DivEx'
import uuidv4 from 'uuid/v4'

const rulesListId = uuidv4()

class Rules extends Component {

    render() {
        return (
            <div className={classes.Rules}>

                <DivEx id={rulesListId} dataKey="rules-list" />

            </div>
        )
    }
}

export default Rules