import React from 'react'
import classes from './Separator.css'

const Separator = () => {

    return (
        <div className={classes.Separator} />
    )
}

export default Separator