import React, { Component } from 'react'
import Auth from './containers/Auth/Auth'
import Admin from './containers/Admin/Admin'
import About from './containers/About/About'
import Details from './containers/Details/Details'
import News from './containers/News/News'
import Rules from './containers/Rules/Rules'
import Map from './containers/Map/Map'
import Logout from './containers/Logout/Logout'
import Layout from './hoc/Layout/Layout'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { autoLogin, logout } from './redux/actions/auth'
import { redirect } from './redux/actions/general'
import { loadGlobals } from './redux/actions/globals'
import RedirectEx from './components/Router/RedirectEx'

class App extends Component {

    layout(component) {
        return (
            <Layout>
                {component}
            </Layout>
            )
    }

    componentWillMount() {
    }

    componentDidMount() {
        document.onkeydown = (e) => {

            if (e.key === 'p' && e.altKey) {
                this.props.redirect('/admin')
            }
        }; 

        //this.props.redirect('/')

        if (this.props.isDemo) {
            this.props.logout()
        }

        this.props.loadGlobals()
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (this.props.redirectTo === nextProps.redirectTo && this.props.redirectTo === null) {
            return false
        }

        return true
    }

    componentWillUnmount() {
        document.onkeydown = null
    }

    render() {

        let routes = (
            <Switch>
                <Route path="/:language(en|ru)/about" exact render={() => this.layout(<About />)} />
                <Route path="/:language(en|ru)/details" exact component={() => this.layout(<Details />)} />
                <Route path="/:language(en|ru)/news" exact component={() => this.layout(<News />)} />
                <Route path="/:language(en|ru)/rules" exact component={() => this.layout(<Rules />)} />
                <Route path="/:language(en|ru)/maps/:maptype(center|north)" exact component={() => this.layout(<Map />)} />
                <Route path="/:language(en|ru)/login" exact component={() => this.layout(<Auth />)} />
                <Route path="/:language(en|ru)" exact component={() => this.layout(<About />)} />
                <Route path="/:language(en|ru)/admin" exact component={() => this.layout(<Admin />)} />
                <Redirect to="/ru" />
            </Switch>
        )

        if (this.props.isAuthenticated || this.props.isDemo) {
            routes = (
                <Switch>
                    <Route path="/:language(en|ru)/about" exact render={() => this.layout(<About />)} />
                    <Route path="/:language(en|ru)/details" exact component={() => this.layout(<Details />)} />
                    <Route path="/:language(en|ru)/news" exact component={() => this.layout(<News />)} />
                    <Route path="/:language(en|ru)/rules" exact component={() => this.layout(<Rules />)} />
                    <Route path="/:language(en|ru)/maps/:maptype(center|north)" exact component={() => this.layout(<Map />)} />
                    <Route path="/:language(en|ru)/admin" exact component={() => this.layout(<Admin />)} />
                    <Route path="/:language(en|ru)/logout" exact component={() => this.layout(<Logout />)} />
                    <Route path="/:language(en|ru)" exact component={() => this.layout(<About />)} />
                    <Redirect to="/ru" />
                </Switch>
            )
        }

        if (this.props.redirectTo !== null) {
            const redirect = (<Switch><RedirectEx to={this.props.redirectTo} relative={false} /></Switch>)
            routes = redirect
        }

        return routes
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.auth.token,
        isDemo: !!state.globals.flags.isDemo,
        redirectTo: state.general.redirectTo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        autoLogin: () => dispatch(autoLogin()),
        logout: () => dispatch(logout()),
        redirect: path => dispatch(redirect(path)),
        loadGlobals: () => dispatch(loadGlobals())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
