import React, { Component } from 'react'
import { connect } from 'react-redux';
import classes from './Layout.css'
import Menu from '../../components/Navigation/Menu/Menu'
import Loading from '../../components/Loading/Loading'
import { setLanguage } from '../../redux/actions/globals'
import { withRouter } from 'react-router-dom'

class Layout extends Component {

    componentDidMount() {
        this.props.setLanguage(this.props.match.params.language)
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.language !== prevProps.match.params.language) {
            this.props.setLanguage(this.props.match.params.language)
        }
    }

    render() {

        const control =
            <React.Fragment>
                <div className={classes.Layout}>
                    <Loading />

                    <Menu />

                    <main>
                        {this.props.children}
                    </main>
                </div>
            </React.Fragment>

        return control
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.auth.token,
        isDemo: !!state.globals.flags.isDemo,
        isLoading: state.globals.flags.isLoading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setLanguage: language => dispatch(setLanguage(language))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout))