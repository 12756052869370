import axios from 'axios'
import { AUTH_LOGOUT, AUTH_SUCCESS } from './actionTypes';
import { redirect } from './general'
import { switchEditMode } from './edit'
import { getFirebase } from '../../data/firebase'

export function auth(email, password, isLogin, redirectPath) {
    return async dispatch => {

        if (isLogin) {
            getFirebase().auth().signInWithEmailAndPassword(email, password).then(data => {
                onSuccess('Login', data, redirectPath, dispatch)
            }).catch(function (error) {
                // console.log('login error', error)
            })
        } else {
            getFirebase().auth().createUserWithEmailAndPassword(email, password).then(data => {
                onSuccess('User create', data, redirectPath, dispatch)
            }).catch(function (error) {
                // console.log('auth create error', error)
            })
        }
    }
}

export function autoLogout(time) {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout())
        }, time * 1000)
    }
}

export function logout() {
    return dispatch => {
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        localStorage.removeItem('expirationDate')
        //localStorage.removeItem('isEditModeActive')

        //dispatch(switchEditMode(false))
        dispatch(logoutInner())
    }
}

export function logoutInner() {
    return {
        type: AUTH_LOGOUT
    }
}

export function autoLogin() {
    return dispatch => {
        const token = localStorage.getItem('token')
        if (!token) {
            dispatch(logout())
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'))
            if (expirationDate <= new Date()) {
                dispatch(logout())
            } else {
                dispatch(authSuccess(token))
                dispatch(autoLogout((expirationDate.getTime() - new Date().getTime()) / 1000))
            }
        }
    }
}

export function authSuccess(token) {
    return {
        type: AUTH_SUCCESS,
        token
    }
}

function onSuccess(message, data, redirectPath, dispatch) {
    const expirationDate = new Date(new Date().getTime() + 3600)

    localStorage.setItem('token', data.user.refreshToken)
    localStorage.setItem('userId', data.user.uid)
    localStorage.setItem('expirationDate', expirationDate)

    dispatch(authSuccess(data.user.refreshToken))
    dispatch(autoLogout(3600))
    dispatch(redirect(redirectPath))
}