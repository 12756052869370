import React, { Component } from 'react'
import classes from './Input.css'
import { connect } from 'react-redux'
import Text from '../Text/Text'
import { getText } from '../../../redux/actions/general'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'

class Input extends Component {

    state = {
        value: this.props.value,
        isValid: true
    }

    isInvalid({ valid, touched, shouldValidate }) {
        return !valid && shouldValidate && touched
    }

    onChangeHandler = e => {

        this.setState({
            value: e.target.value
        })

        this.validate()

        if (this.props.onChange !== undefined) {
            this.props.onChange(e)
        }
    }

    validate = () => {
        this.setState((prevState, props) => ({
            isValid: prevState.value !== ''
        }))
    }

    render() {
        const inputType = this.props.type || 'text'
        const cls = [classes.Input]
        const htmlFor = `${inputType}-${Math.random()}`

        if (this.isInvalid(this.props)) {
            cls.push(classes.invalid)
        }

        let questionLabel = null
        if (!this.state.isValid) {
            const emptyWarning = this.props.getText('empty-warning')
            questionLabel = <span title={emptyWarning}><FontAwesomeIcon icon={faQuestion} className={classes.InvalidLabel} fixedWidth size="1x" /></span>
            cls.push(classes.invalid)
        }

        let input = <input
                        type={inputType}
                        id={htmlFor}
                        value={this.state.value}
                        onChange={e => this.onChangeHandler(e)} />

        if (this.props.multiline !== undefined) {
            input = <textarea
                            id={htmlFor}
                            rows={this.props.multiline}
                            value={this.state.value}
                            onChange={e => this.onChangeHandler(e)} />
        }

        let component =
            <div className={cls.join(' ')}>
                <label htmlFor={htmlFor}>{this.props.label} {questionLabel}</label> 
                {input}
            </div>

        if (this.props.label === undefined) {
            component =
                <div className={cls.join(' ')}>
                    {input}
                </div>
        }

        return component
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getText: key => dispatch(getText(key))
    }
}

export default connect(null, mapDispatchToProps)(Input)
