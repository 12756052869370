export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SWITCH_EDIT_MODE = 'SWITCH_EDIT_MODE';
export const REDIRECT = 'REDIRECT'
export const RESET_REDIRECT = 'RESET_REDIRECT'
export const CONTROLS_UPDATE_LIST = 'CONTROLS_UPDATE_LIST'
export const CREATE_REFERENCE_TREE = 'CREATE_REFERENCE_TREE'
export const SAVE_REFERENCE = 'SAVE_REFERENCE'
export const FETCH_LIST_SUCCESS = 'FETCH_LIST_SUCCESS'
export const FETCH_LIST_ERROR = 'FETCH_LIST_ERROR'
export const RESET_LIST_CREATION = 'RESET_LIST_CREATION'
export const CANCEL_LIST_LOCAL_CHANGES = 'CANCEL_LIST_LOCAL_CHANGES'
export const SAVE_FLAGS = 'SAVE_FLAGS'
export const CANCEL_ACTION = 'CANCEL_ACTION'
export const CONTROLS_UPDATE = 'CONTROLS_UPDATE'
export const CONTROLS_UPDATE_LOCAL = 'CONTROLS_UPDATE_LOCAL'
export const CONTROL_UPDATE_LOADING_FLAG = 'CONTROL_UPDATE_LOADING_FLAG'
export const CONTROLS_REMOVE_ALL = 'CONTROLS_REMOVE_ALL'
export const UPDATE_GLOBALS = 'UPDATE_GLOBALS'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const LOAD_GLOBALS = 'LOAD_GLOBALS'