import React, { Component } from 'react'
import { connect } from 'react-redux'
import classes from './Edit.css'
import Text from '../../UI/Text/Text'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import BasicControl from '../../../data/BasicControl'
import { getControlFromState, updateControlState, deleteControl } from '../../../redux/actions/controlsEx'

class Edit extends Component {

    constructor(props) {
        super(props)
        this.ruRef = React.createRef()
        this.enRef = React.createRef()

        if (props.data !== undefined && props.data !== null && props.data.length !== 0) {
            this.state = {
                ru: this.props.data.ru,
                en: this.props.data.en,
                text: this.props.data.text !== undefined ? this.props.data.text : null
            }
        } else {
            this.state = {
                ru: null,
                en: null,
                text: null
            }
        }

        if (!!this.props.control && this.props.control.type === 'text-array') {

            let data = null
            if (!!this.props.control.data) {
                this.isNew = false
                data = this.props.control.data
            } else if (!!this.props.control.dataTemplate) {
                this.isNew = true
                data = this.props.control.dataTemplate
            }

            data.every(item => {

                const ruKey = this.getKey(item, 'ru')
                const enKey = this.getKey(item, 'en')
                const textKey = this.getKey(item, 'text')

                this.state[ruKey] = item.ru
                this.state[enKey] = item.en
                this.state[textKey] = item.text

                return true
            })
        }
    }

    prepareState() {
        this.props.control.data.every(item => {

            const ruKey = this.getKey(item, 'ru')
            const enKey = this.getKey(item, 'en')
            const textKey = this.getKey(item, 'text')

            this.setState({
                ...this.state,
                [ruKey]: item.ru,
                [enKey]: item.en,
                [textKey]: item.text
            })

            return true
        })
    }

    applyHandler() {
        this.props.onApply({ en: this.state.en, ru: this.state.ru, text: this.state.text  })
    }

    applyMultiHandler(e) {

        e.preventDefault()

        this.props.control.data.every(control => {

            const ruKey = this.getKey(control, 'ru')
            const enKey = this.getKey(control, 'en')
            const textKey = this.getKey(control, 'text')
            const data = { en: this.state[enKey], ru: this.state[ruKey], text: this.state[textKey] }
            control.update(data)

            return true
        })

        this.props.rootControl.flags.isChanged = true

        updateControlState(this.props.control, this.props.rootControl)

        this.props.onCancel()
    }

    cancelHandler() {
        this.props.onCancel()
    }

    cancelMultiHandler() {
        this.props.onCancel()
    }

    deleteHandler() {
        this.props.onDelete()
    }

    deleteMultiHandler() {
        this.props.deleteControl(this.props.control, this.props.rootControl)
        this.props.onCancel()
    }

    onChangeHandler(e, controlName) {
        this.setState({
            [controlName]: e.target.value
        })
    }

    onMultiChangeHandler(e, control, propertyName) {
        const key = this.getKey(control, propertyName);

        this.setState({
            [key]: e.target.value
        })
    }

    getKey(control, propertyName) {
        return `${control.id}-${propertyName}`
    }

    getSingleTextControl() {
        const cls = [
            classes.Edit
        ]

        const clsInner = [
            classes.EditInner,
            classes.box,
            classes.box4,
            classes.shadow4
        ]

        const russianText = <Text global="russian" />
        const englishText = <Text global="english" />

        const iconButtons = this.props.onDelete !== undefined ?
            <div className={classes.IconButtons}>
                <div className={classes.SlidingButton} onClick={() => this.deleteHandler()}>
                    <div className={classes.SlidingButtonInner}>
                        <span><Text global="delete" /></span>
                    </div>
                    <FontAwesomeIcon icon={faTrashAlt} className={classes.Delete} fixedWidth size="1x" />
                </div>
            </div>
            :
            null

        const inputs = this.state.text !== null ?
            <Input value={this.state.text} label="All languages" multiline={2} onChange={e => this.onChangeHandler(e, 'text')} />
            :
            <React.Fragment>
                <Input value={this.state.ru} label={russianText} multiline={2} onChange={e => this.onChangeHandler(e, 'ru')} />
                <Input value={this.state.en} label={englishText} multiline={2} onChange={e => this.onChangeHandler(e, 'en')} />
            </React.Fragment>

        const control =
            <div className={cls.join(' ')}>
                <div className={clsInner.join(' ')}>

                    {inputs}

                    <div className={classes.Buttons}>
                        <div className={classes.MainButtons}>
                            <Button
                                type="success"
                                onClick={() => this.applyHandler()}
                            >
                                <Text global="accept" />
                            </Button>

                            <Button
                                type="warning"
                                onClick={() => this.cancelHandler()}
                            >
                                <Text global="cancel" />
                            </Button>
                        </div>

                        {iconButtons}
                    </div>
                </div>
            </div>

        return control
    }

    getMultiTextControl() {
        if (!!!this.props.control) {
            return null
        }

        if (!this.props.control.flags.isEditEnabled) {
            return null
        }

        const cls = [
            classes.Edit
        ]

        const clsInner = [
            classes.EditInner,
            classes.box,
            classes.box4,
            classes.shadow4
        ]

        const russianText = <Text global="russian" />
        const englishText = <Text global="english" />

        const iconButtons = 
            <div className={classes.IconButtons}>
                <div className={classes.SlidingButton} onClick={() => this.deleteMultiHandler()}>
                    <div className={classes.SlidingButtonInner}>
                        <span><Text global="delete" /></span>
                    </div>
                    <FontAwesomeIcon icon={faTrashAlt} className={classes.Delete} fixedWidth size="1x" />
                </div>
            </div>

        const inputControls = this.props.control.data.map((item, i) => {

            if (item.text === 'Announcer by ago') {
                // debugger
            }

            const inputs = !!item.text ?
                <Input value={this.state[this.getKey(item, 'text')]} label="All languages" multiline={2} onChange={e => this.onMultiChangeHandler(e, item, 'text')} />
                :
                <React.Fragment>
                    <Input value={this.state[this.getKey(item, 'ru')]} label={russianText} multiline={2} onChange={e => this.onMultiChangeHandler(e, item, 'ru')} />
                    <Input value={this.state[this.getKey(item, 'en')]} label={englishText} multiline={2} onChange={e => this.onMultiChangeHandler(e, item, 'en')} />
                </React.Fragment>

            const inputControl =
                <React.Fragment key={i}>
                    {inputs}
                    <hr />
                </React.Fragment>


            return inputControl
        })

        const control =
            <div className={cls.join(' ')}>
                <div className={clsInner.join(' ')}>

                    {inputControls}

                    <div className={classes.Buttons}>
                        <div className={classes.MainButtons}>
                            <Button
                                type="success"
                                onClick={e => this.applyMultiHandler(e)}
                            >
                                <Text global="accept" />
                            </Button>

                            <Button
                                type="warning"
                                onClick={() => this.cancelMultiHandler()}
                            >
                                <Text global="cancel" />
                            </Button>
                        </div>

                        {iconButtons}
                    </div>
                </div>
            </div>

        return control
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.control.id !== this.props.control.id) {
            this.prepareState()
        }
    }

    render() {

        if (!this.props.isEditGlobal || !!!this.props.control) {
            return null
        }

        let control = null

        if (this.props.control.type === 'text') {
            control = this.getSingleTextControl()
        } else if (this.props.control.type === 'text-array') {
            control = this.getMultiTextControl()
        }

        return control
    }
}


function mapStateToProps(state, initialProps) {
    const controlId = initialProps.data !== undefined ? initialProps.data.id : initialProps.id === undefined ? null : initialProps.id
    const controlFromState = getControlFromState(state.controls, initialProps.dataKey, controlId, 'local')

    return {
        isEditGlobal: state.edit.isEditModeActive,
        control: controlFromState.control === undefined ? null : controlFromState.control,
        parentControl: controlFromState.control !== null && controlFromState.control.parent !== undefined ? controlFromState.control.parent : null,
        rootControl: controlFromState.rootControl
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateControlState: (control, rootControl) => dispatch(updateControlState(control, rootControl)),
        deleteControl: (control, rootControl) => dispatch(deleteControl(control, rootControl))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Edit)