import firebase from 'firebase'

export function getFirebase() {

    /*
    /// DEV

    const firebaseConfig = {
        apiKey: "AIzaSyCieqENhGkWRENb4nDtw54u24wDiHT6rjs",
        authDomain: "silver-blades.firebaseapp.com",
        databaseURL: "https://silver-blades.firebaseio.com",
        projectId: "silver-blades",
        storageBucket: "silver-blades.appspot.com",
        messagingSenderId: "319772503591",
        appId: "1:319772503591:web:79a8b25b27760e8c"
    }
    */

    /// Demo

    const firebaseConfig = {
        apiKey: "AIzaSyA-W358xlXf4yxLGu3cNbMeuz_1hp8VXsA",
        authDomain: "silver-blades-demo-c4abf.firebaseapp.com",
        databaseURL: "https://silver-blades-demo-c4abf.firebaseio.com",
        projectId: "silver-blades-demo-c4abf",
        storageBucket: "",
        messagingSenderId: "107748934697",
        appId: "1:107748934697:web:081dd11eccbf76e7ef4838",
        measurementId: "G-2XB6ZR697Z"
    }

    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig)
    }

    return firebase.app()
}