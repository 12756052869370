import { UPDATE_GLOBALS, LOAD_GLOBALS, SET_LANGUAGE } from '../actions/actionTypes'
import { deepCopyEx } from '../actions/general'

const initialState = {
    flags: {
        isDemo: true,
        isLoading: true,
        isLoadingComplete: false
    },
    controls: {},
    language: null
}

export default function globalsReducer(state = initialState, action) {

    console.log('globalsReducer state', deepCopyEx(state))
    console.log('globalsReducer action', action)

    switch (action.type) {
        case LOAD_GLOBALS:
            const key = Object.keys(action.payload.controls)[0]
            return {
                ...state,
                flags: action.payload.flags,
                controls: action.payload.controls[key]
            }
        case UPDATE_GLOBALS:
            return {
                ...action.payload
            }
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        default:
            return state
    }
}