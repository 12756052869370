import { getFirebase } from '../../data/firebase'
import { deepFindEx, deepReplaceEx, findRefs, checkRefs, deepCopyEx, parseTagsEx } from './general'
import ControlFactory from '../../data/ControlFactory'
import BaseControl from '../../data/BaseControl'
import BasicControl from '../../data/BasicControl'
import ComplexControl from '../../data/ComplexControl'
import ControlState from '../../data/ControlState'
import { getData } from '../../data/language'
import ControlFlags from '../../data/ControlFlags';
import {
    CONTROLS_UPDATE,
    CONTROLS_REMOVE_ALL
} from './actionTypes'

const basicControls = ['text', 'text-array', 'tag']

let loadingQueue = []

export function loadList(dataKey = null, controlId = null) {

    if (dataKey === null) {
        return
    }

    return (dispatch, getState) => {

        if (controlId === null) {
            if (loadingQueue.includes(dataKey)) {
                return
            }

            loadingQueue.push(dataKey)
            loadListInner(dataKey)
            return
        }

        if (loadingQueue.includes(controlId)) {
            return
        }

        loadingQueue.push(controlId)

        const state = getState().controls

        const controlFromState = getControlFromState(state, dataKey, controlId, 'local')
        const parentControl = controlFromState.control !== null ? controlFromState.control.parent : null

        dispatch(loadListInner(dataKey, controlId, controlFromState.control, controlFromState.rootControl, parentControl))
    }
}

export function getControlFromState(state, dataKey = null, controlId = null, controlStateToUse = 'local') {

    const controlFromState = findControlInState(state, controlId, dataKey, controlStateToUse)

    const result = {
        control: null,
        rootControl: null,
        pathInParentControl: []
    }

    if (controlFromState === null) {
        return result
    }

    result.control = controlFromState.control // controlFromState
    result.rootControl = controlFromState.rootControl //getRoot(controlFromState)

    return result
}

export function updateControlState(controlToUpdate = null, rootControl = null, isRefsNotFound = false) {

    return dispatch => {

        let control = rootControl === null ? controlToUpdate : rootControl
        const controlToUpdateFinal = controlToUpdate === null ? rootControl : controlToUpdate

        dispatch(updateRootControl(controlToUpdateFinal, rootControl, isRefsNotFound))
        dispatch(updateState(control))
    }
}

export function cancelLocalChanges(control) {
    return dispatch => {

        if (!(control instanceof ComplexControl)) {
            throw new Error('Object should be of \'ComplexControl\' type')
        }

        control.localState = deepControlCopy(control.serverState, control)
        setFlags(control, ['isChanged'], false)

        dispatch(updateState(control))
    }
}

export function saveControl(rootControl, onSuccess, onError) {

    return dispatch => {

        const firebase = getFirebase()

        const serverControl = getRaw(rootControl)
        const promises = saveControlInner(firebase, serverControl)

        Promise.all(promises).then(values => {
            onSuccess(values)

            setFlags(rootControl, ['isChanged'], false)
            dispatch(updateState(rootControl))

        }).catch(error => {
            onError(error)
        })
    }
}

export function removeAllControls() {

    //const firebase = getFirebase()
    getFirebase().database().ref().child('controls').off()
    loadingQueue = []

    return {
        type: CONTROLS_REMOVE_ALL
    }
}

function saveControlInner(firebase, serverControl, promises = []) {

    const serverId = Object.keys(serverControl)[0]
    const serverObj = serverControl[serverId]

    const promise = firebase.database().ref('controls/' + serverId).set(serverObj, res => {
        // console.log('saveList set success', res)
    })

    promises.push(promise)

    serverControl.childControls.every(childControl => {
        saveControlInner(firebase, childControl, promises)
        return true
    })

    return promises
}

/// Sets flags for all controls starting from current and ending at root
export function setFlags(control, flags, value) {
    if (control === null) {
        return null
    }

    flags.every(key => {
        control.flags[key] = value
        return true
    })

    setFlags(control.parent, flags, value)
}

export function deleteControl(control, rootControl) {

    return dispatch => {
        if (control.parentKey === null || control.parent === null) {
            return
        }

        const propertyInParentControl = getProperty(control.parent, control.parentKey)

        const lastKey = control.parentKey[control.parentKey.length - 1]

        if (propertyInParentControl instanceof Array) {
            propertyInParentControl.splice(lastKey, 1)
            refreshParentKeys(propertyInParentControl)
        } else if (propertyInParentControl instanceof Object) {
            delete propertyInParentControl[lastKey]
        }

        setFlags(control, ['isChanged'], true)
        createBasicControls(rootControl)

        dispatch(updateControlState(null, rootControl))
    }
}

export function createBasicControls(obj, parentObj = null, propertiesToIgnore = ['parent', 'serverStateRaw', 'serverState'], parentKey = []) {

    let result = null

    if (typeof obj !== 'object' || obj === null) {
        return result
    }

    if (obj instanceof Date) {
        return result
    }

    if (obj instanceof Array) {
        obj.every((item, i) => {
            const temp = createBasicControls(item, parentObj, propertiesToIgnore, parentKey)
            if (temp !== null) {
                const tempKey = deepCopyEx(parentKey)
                tempKey.push(i)
                temp.parentKey = tempKey

                obj[i] = temp
                return true
            }

            return true
        });
    }

    if (obj instanceof Object) {

        Object.keys(obj).every(key => {

            if (propertiesToIgnore.includes(key)) {
                return true
            }

            if (key === 'type'
                && !(obj instanceof BaseControl)
                && !(obj instanceof ControlState)) {
                result = basicControls.includes(obj[key]) ? new BasicControl(obj, null, parentObj) : new ComplexControl(obj, null, parentObj)
                return false
            }

            let tempParentObj = parentObj
            let tempParentKey = parentKey

            if (obj instanceof BaseControl || obj instanceof ControlState) {
                tempParentKey = deepCopyEx(parentKey)
                tempParentKey.push(key)
            }

            if (obj instanceof BaseControl) {
                tempParentObj = obj
            }

            const temp = createBasicControls(obj[key], tempParentObj, propertiesToIgnore, tempParentKey)
            if (temp !== null) {
                const tempKey = deepCopyEx(parentKey)
                tempKey.push(key)
                temp.parentKey = tempKey
                obj[key] = temp
                return true
            }

            return true
        })
    }

    return result
}

export function createBasicControlsEx(obj, isNewIdRequired = false, parentObj = null, propertiesToIgnore = ['parent', 'serverStateRaw', 'serverState']) {

    let result = null

    if (typeof obj !== 'object' || obj === null) {
        return result
    }

    if (obj instanceof Date) {
        return result
    }

    if (obj instanceof Array) {
        obj.every((item, i) => {
            const temp = createBasicControlsEx(item, isNewIdRequired, parentObj, propertiesToIgnore)
            if (temp !== null) {
                obj[i] = temp
                return true
            }

            return true
        });
    }

    if (obj instanceof Object) {

        if ((!!obj.type)
            && !(obj instanceof BaseControl)
            && !(obj instanceof ControlState)) {
            result = basicControls.includes(obj.type) ? new BasicControl(obj, null, parentObj) : new ComplexControl(obj, null, parentObj)
            return result
        }

        Object.keys(obj).every(key => {

            if (propertiesToIgnore.includes(key)) {
                return true
            }

            if (obj[key] instanceof BaseControl) {
                obj[key] = ControlFactory.copy(obj[key], isNewIdRequired)
                return true
            }
        })

        result = obj
    }

    return result
}

export function deepControlCopy(obj, parentObj = null, propertiesToIgnore = ['id', 'parent', 'serverStateRaw', 'serverState']) {

    if (typeof obj !== 'object' || obj === null) {
        return obj
    }

    if (obj instanceof Date) {
        return obj
    }

    if (obj instanceof Array) {

        const tempArray = []
        obj.every((item, i) => {
            const temp = deepControlCopy(item, parentObj, propertiesToIgnore)
            if (temp !== null) {
                tempArray.push(temp)
                refreshParentKeys(tempArray, parentObj)
                return true
            }

            return true
        })

        return tempArray
    }

    if (obj instanceof Object) {

        if (!!!obj.type && !(obj instanceof BaseControl) && !(obj instanceof ControlState) && !(obj instanceof ControlFlags)) {
            obj = { ...obj }
        } else if (!!obj.type && !(obj instanceof BaseControl) && !(obj instanceof ControlState) && !(obj instanceof ControlFlags)) {
            // transform Object into BaseControl (if there is type property)
            obj = basicControls.includes(obj.type) ? new BasicControl(obj, null, parentObj) : new ComplexControl(obj, null, parentObj)
            // Set current obj to be parent to its children
            parentObj = obj 
        } else if (!!obj.type && obj instanceof BaseControl) {
            // copy BaseControl
            obj = obj instanceof BasicControl ? new BasicControl(obj, null, parentObj) : new ComplexControl(obj, null, parentObj)
            // Set current obj to be parent to its children
            parentObj = obj
        }

        if (obj instanceof ControlState) {
            obj = new ControlState(obj)
        } else if (obj instanceof ControlFlags) {
            obj = new ControlFlags(obj)
        }

        Object.keys(obj).every(key => {

            if (propertiesToIgnore.includes(key)) {
                return true
            }

            if (typeof obj[key] === 'function') {
                return true
            }

            const temp = deepControlCopy(obj[key], parentObj, propertiesToIgnore)
            obj[key] = temp

            return true
        })
    }

    return obj
}

export function addData(control, rootControl, data, isPush = true) {

    return dispatch => {
        let controlState = null

        if (control instanceof ComplexControl) {
            controlState = control.localState
        } else if (control instanceof BasicControl) {
            controlState = control
        }

        data.type = !!data.type ? data.type : 'text' 

        const temp = data instanceof BasicControl ? data : new BasicControl(data, null, control)

        if (isPush) {
            controlState.data.push(temp)
        } else {
            controlState.data.unshift(temp)
        }

        temp.parentKey = null
        refreshParentKeys(controlState.data)

        setFlags(rootControl, ['isChanged'], true)
        dispatch(updateControlState(null, rootControl))
    }
}

export function createServiceControl(controlName) {
    return async (dispatch, getState) => {

        const data = getData()
        const firebase = getFirebase()
        const ref = firebase.database().ref()

        firebase.database().ref().child('globals').child('controls').remove().then(() => {

            firebase.database().ref().child('globals').child('controls').push(data['GlobalControls'], res => {
                console.log('createList push success', res)
            })
        })

        /*
        const query = firebase.database().ref().child('controls').orderByChild('dataKey').equalTo(controlName).once('value', snapshot => {


            const promisses = []
            snapshot.forEach(data => {

                var record = data.val()
                const promiss = ref.child('controls').child(data.key).remove()

                promisses.push(promiss)

                return true
            })

            Promise.all([promisses])

            firebase.database().ref().child('controls').push(data[controlName], res => {
                console.log('createList push success', res)
            })
        })

        firebase.database().ref('controls').orderByChild('dataKey').equalTo('details-list-2').once('value', snapshot => {

            const serverId = Object.keys(snapshot.val())[0]
            snapshot.remove()
        })
        */
        /*
        query.get().then((snapshot) => {
            debugger
        })
        */
        /*
        firebase.database().ref().child('controls').orderByChild('dataKey').equalTo('details-list-2').remove().then(() => {
            alert('deleted')
            firebase.database().ref().child('controls').push(data['details-list-2'], res => {
                console.log('createList push success', res)
            })
        })
        */
        /*

        firebase.database().ref().child('globals').child('controls').remove().then(() => {

            firebase.database().ref().child('globals').child('controls').push(data['GlobalControls'], res => {
                console.log('createList push success', res)
            })
        })
        


        firebase.database().ref().child('controls').push(data['news-list'], res => {
            console.log('createList push success', res)
        })
        
        firebase.database().ref().child('controls').push(data['about-list-1-data-1'], res => {
            console.log('createList push success', res)
        })

        firebase.database().ref().child('controls').push(data['about-list-1-data-2'], res => {
            console.log('createList push success', res)
        })

        firebase.database().ref().child('controls').push(data['about-list-1-data-3'], res => {
            console.log('createList push success', res)
        })
        */
    }
}

export function updateControl(control, data) {

    if (control instanceof BasicControl) {

        Object.keys(data).every(property => {

            if (data[property] === undefined || data[property] === null) {
                return true
            }

            if (property === 'id') {
                return true
            }

            const parseResult = parseTagsEx(data[property])
            control[property] = data[property]
            if (!(parseResult === data[property])) {
                control[`${property}-parsed-tags`] = parseResult
            }
            return true
        })
    }

    if (control instanceof ComplexControl) {
        control.serverStateRaw = deepCopyEx(data)

        if (control.serverState === null) {
            control.serverState = new ControlState(control.serverStateRaw)
        }

        if (!this.flags.isEditEnabled) {
            control.localState = new ControlState(control.serverState)
        }
    }
}

function getRaw(control, templateParam = null) {

    let result = null

    if (control instanceof ComplexControl) {
        result = getRawComplex(control)
    } else if (control instanceof BasicControl) {
        result = getRawBasic(control, templateParam)
    }

    return result
}

function getRawComplex(control) {
    const template = control.serverStateRaw
    const localState = control.localState
    const result = {}

    const resultFinal = {
        [control.serverId]: result,
        childControls: []
    }

    Object.keys(template).every(property => {

        if ((typeof template[property] === 'function') || (localState[property] === undefined) || (property === 'parent')) {
            return true
        }

        if (property === 'dataTemplate') {
            result[property] = template[property]
            return true
        }

        if (localState[property] instanceof Array) {

            result[property] = []

            localState[property].every((item, i) => {

                let temp = item

                if (!!template[property][i] && template[property][i].ref !== undefined) {
                    temp = createRef(item)
                    const childObj = getRaw(item)
                    resultFinal.childControls.push(childObj)
                } else if (item instanceof BasicControl) {
                    if (!!template[property][i]) {
                        // update item
                        temp = getRaw(item, template[property][i])
                    } else {
                        // add new item
                        /*
                        const tempTemplate = new BasicControl(template.dataTemplate)
                        tempTemplate.data = template.dataTemplate
                        delete tempTemplate.dataTemplate
                        temp = getRaw(item, tempTemplate.data)
                        */
                        temp = getRaw(item, template.dataTemplate)
                    }
                } else if (item instanceof ComplexControl) {
                    temp = getRaw(item)
                }

                result[property].push(temp)

                return true
            });

            return true
        }

        if (localState[property] instanceof BasicControl) {
            result[property] = getRaw(localState[property], template[property])
            return true
        }

        if (localState[property] instanceof ComplexControl) {

            if (template[property].ref !== undefined) {
                result.ref = createRef(localState[property])
                const childObj = getRaw(localState[property])
                resultFinal.childControls.push(childObj)
                return true
            }

            result[property] = getRaw(localState[property])

            return true
        }

        result[property] = localState[property]
        return true
    })

    return resultFinal
}

function createRef(control) {
    return { ref: control.dataKey }
}

function getRawBasic(control, templateParam = null) {
    const template = { ...templateParam }
    const localState = control
    let result = {}

    if (!!template.dataTemplate) {
        template.data = template.dataTemplate
    }

    Object.keys(template).every(property => {

        if ((typeof template[property] === 'function') || (localState[property] === undefined) || (property === 'parent')) {
            return true
        }

        if (property === 'dataTemplate') {
            result[property] = template[property]
            return true
        }

        if (template[property] === undefined || template[property] === null) {
            return true
        }

        if (localState[property] instanceof Array) {

            result[property] = []

            localState[property].every((item, i) => {

                let temp = item

                if (template[property][i].ref !== undefined) {
                    temp = createRef(item)
                    const childObj = getRaw(item)
                } else if (item instanceof BasicControl) {
                    temp = getRaw(item, template[property][i])
                } else if (item instanceof ComplexControl) {
                    temp = getRaw(item)
                }

                result[property].push(temp)

                return true
            });

            return true
        }

        if (localState[property] instanceof BasicControl) {
            result[property] = getRaw(localState[property], template[property])
            return true
        }

        if (localState[property] instanceof ComplexControl) {

            if (template[property].ref !== undefined) {
                result.ref = createRef(localState[property])
                const childObj = getRaw(localState[property])
                return true
            }

            result[property] = getRaw(localState[property])

            return true
        }

        result[property] = localState[property]
        return true
    })

    return result
}

function refreshParentKeys(array, parentObj) {

    array.every((item, i) => {
        
        if (!(item instanceof BaseControl)) {
            return true
        }

        if (item.parentKey === null) {
            item.parentKey = parentObj === null || parentObj instanceof ComplexControl ? ['localState', 'data', i] : ['data', i]
        }

        const lastParentIndex = item.parentKey.length - 1
        item.parentKey[lastParentIndex] = i

        return true
    })
}

function loadListInner(dataKey, controlId = null, control = null, rootControl = null, parentControl = null) {

    if (dataKey === 'about-list-1-data-1') {
        // debugger
    }

    return dispatch => {

        getFirebase().database().ref().child('controls').orderByChild('dataKey').equalTo(dataKey).on('value', (childSnapshot, prevChildKey) => {
            if (childSnapshot.val() === null) {
                return
            }

            if (dataKey === 'about-list-1') {
                // debugger
            }

            const serverId = Object.keys(childSnapshot.val())[0]
            const data = childSnapshot.val()[serverId]
            let controlToUpdate = null

            if (dataKey === 'about-list-1-data-1') {
                // debugger
            }

            if (control === null) {
                controlToUpdate = ControlFactory.create(data, controlId, parentControl, serverId)
            } else {
                controlToUpdate = control.control
                controlToUpdate.update(data)
            }

            if (rootControl === null) {
                rootControl = controlToUpdate
            }

            const isRefsFoundInRootControl = rootControl === null ? false : checkRefs(rootControl.serverState)
            const isRefsFound = checkRefs(controlToUpdate.serverState)

            if (rootControl !== null) {
                rootControl.flags.isLoading = isRefsFoundInRootControl
            }

            controlToUpdate.flags.isLoading = isRefsFound

            if (controlToUpdate.dataKey === 'about-list-1-data-3') {
                //debugger
            }

            dispatch(updateControlState(controlToUpdate, rootControl, !isRefsFound))

            // WARNING - reqursive call 'loadList'
            dispatch(loadRefs(controlToUpdate, rootControl))
        })
    }
}

/*
function startLoading(controlId, rootControlId, controlPath, dispatch) {

    const control = createControlLoadingState(controlId)
    const rootControl = updateRootControl(control, rootControlId, controlPath, false, state)

    dispatch(updateState(rootControl))
}
*/

function loadRefs(control = null, rootControl = null) {

    return dispatch => {

        const controlId = control === rootControl ? null : control.id

        const refs = []
        findRefs(control.serverState, refs)

        if (control.dataKey === 'about-list-1-data-2') {
            // debugger
        }

        if (refs.length > 0) {

            refs.every(ref => {
                const nestedControlDataKey = ref.ref
                //const nestedControlPath = ref.path

                dispatch(loadListInner(nestedControlDataKey, controlId, null, rootControl, control))

                return true
            })

            return true
        }

        return false
    }
}

function findControlInState(state, controlId = null, dataKey = null, controlStateToUse = 'local') {

    if (dataKey === 'about-list-1') {
        // debugger
    }

    if (controlId === null) {
        return null
    }

    const result = {
        control: null,
        rootControl: null,
        pathInParentControl: []
    }

    const keys = Object.keys(state)
    const propertiesToIgnore = ['parent', 'serverStateRaw']

    if (controlStateToUse === 'local') {
        propertiesToIgnore.push('serverState')
    } else {
        propertiesToIgnore.push('localState')
    }

    Object.keys(state).every(key => {

        if (state[key] === undefined || state[key] === null) {
            return true
        }

        if (state[key].dataKey === 'about-list-2') {
            //debugger
        }

        result.control = deepFindEx(state[key], 'id', controlId, propertiesToIgnore)
        if (result.control !== null) {
            result.rootControl = state[key]
            return false
        }

        if (dataKey !== null) {
            result.control = deepFindEx(state[key], 'dataKey', dataKey, propertiesToIgnore)
        }
        if (result.control !== null) {
            result.rootControl = state[key]
            return false
        }

        return true
    })

    return result
}

function updateRootControl(control = null, rootControl = null, isRefsNotFound = false) {

    return dispatch => {

        if (rootControl === null) {
            return
        }

        if (rootControl.dataKey === 'about-list-1') {
            // debugger
        }

        deepReplaceEx(rootControl, control, 'ref', control.dataKey)

        /*
        const result = new ComplexControl(rootControl, rootControl.id)

        if (rootControl === result) {
            // debugger
        }
        */
    }
}

function getProperty(control, path) {

    let property = control
    path.every((nextPropertyName, i) => {

        if (i === path.length - 1) {
            return false
        }

        property = property[nextPropertyName]

        return true
    })

    return property
}

function updateState(control) {

    return {
        type: CONTROLS_UPDATE,
        payload: control
    }
}